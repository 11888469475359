import React, { useState } from 'react';
import './App.css';
import AppContext from './contexts/AppContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SideBar from './components/sidebar/SideBar';
import Content from './components/content/Content';
import Login from './pages/Login/Login';

const App = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  return (
    <AppContext>
      <BrowserRouter>
        <Routes>
          <Route
            path='/*'
            element={
              <div className='App wrapper'>
                <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
                <Content
                  toggleSidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                />
              </div>
            }
          />

          <Route path='/login' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </AppContext>
  );
};

export default App;
