import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  // Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
// import moment from 'moment';

import { useLocation } from 'react-router-dom';
import GlobalContext from '../../contexts/GlobalContext';
// import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import classes from '../sidebar/SideBar.module.css';
const Topbar = (props) => {
  let navigate = useNavigate();

  const { toggleSidebar } = props;
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const { pathname } = useLocation();
  const [PageName, setPageName] = React.useState();
  // const [alertDropdown, setAlertDropdown] = useState(false);
  // const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [compteDropdown, setCompteDropdown] = useState(false);

  const { user, logoutHandler } = React.useContext(GlobalContext);
  // const [alerts, setAlerts] = React.useState(null);
  // const [notifications, setNotifications] = React.useState(null);
  // const [newAlerts, setNewAlerts] = React.useState(0);
  // const [newNotifications, setNewNotifications] = React.useState(0);

  React.useEffect(() => {
    switch (true) {
      case pathname === '/':
        setPageName('My Account');
        break;
      case pathname === '/utilisateurs':
        setPageName('Users');
        break;
      case pathname === '/clients':
        setPageName('Clients');
        break;
      case pathname === '/alerts':
        setPageName('Alerts');
        break;
      case pathname.includes('/clients/'):
        setPageName('Clients details');
        break;
      case pathname.includes('/pool/'):
        setPageName('Pool details');
        break;
      case pathname === '/teams':
        setPageName('Teams');
        break;
      case pathname === '/interventions':
        setPageName('Interventions');
        break;
      case pathname.includes('/interventions/'):
        setPageName('Intervention Sheet');
        break;
      case pathname.includes('/utilisateurs/'):
        setPageName('User details');
        break;
      case pathname.includes('/teams/'):
        setPageName('Team details');
        break;
      case pathname === '/calendrier':
        setPageName('Calendrier');
        break;
      case pathname === '/compte':
        setPageName('Profile');
        break;
      default:
        setPageName('Tableau de bord');
        break;
    }
  }, [pathname]);
  // React.useEffect(() => {
  //   user &&
  //     axios
  //       .get(`/notification/?recipient=${user.username}&type=alert&page=1`)
  //       .then((res) => {
  //         setAlerts(res.data.notifications);
  //         var temp = [];
  //         res.data.notifications.map((notification) => {
  //           if (
  //             new Date(notification.creation_date)
  //               .toISOString()
  //               .split('T')[0] === new Date().toISOString().split('T')[0]
  //           ) {
  //             temp.push(notification);
  //           }
  //           setNewAlerts(temp.length);
  //           return true;
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   user &&
  //     axios
  //       .get(`/notification/?recipient=${user.username}&type=activity&page=1`)
  //       .then((res) => {
  //         setNotifications(res.data.notifications);
  //         var temp = [];
  //         res.data.notifications.map((notification) => {
  //           if (
  //             new Date(notification.creation_date)
  //               .toISOString()
  //               .split('T')[0] === new Date().toISOString().split('T')[0]
  //           ) {
  //             temp.push(notification);
  //           }
  //           setNewNotifications(temp.length);
  //           return true;
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  return (
    <Navbar
      color='light'
      light
      className='navbar shadow-sm p-3 mb-0 bg-white rounded'
      expand='md'
    >
      <Button
        style={{ backgroundColor: '#A4DEE6', border: 'none' }}
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} className='colap' navbar>
        <div
          style={{
            paddingLeft: '20px',
          }}
        >
          {PageName && <h4 style={{ margin: '0px' }}>{PageName}</h4>}
        </div>
        <Nav className='ml-auto' navbar>
          <div style={{ display: 'flex' }}>
            {/* <NavItem>
              <Dropdown
                isOpen={alertDropdown}
                toggle={() => {
                  setAlertDropdown(!alertDropdown);
                }}
              >
                <DropdownToggle
                  nav
                  style={{ position: 'relative', marginRight: '1rem' }}
                >
                  <Badge
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      left: '-3px',
                      zIndex: '1',
                    }}
                    color='danger'
                    pill
                  >
                    0
                  </Badge>
                  <img
                    style={{ width: '25px', height: '25px' }}
                    src={require('../../assets/images/msg.png')}
                    alt='messanger'
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <></>
                  {alerts &&
                    alerts.map((alert, index) => (
                      <DropdownItem
                        key={index}
                        style={{
                          paddingVertical: 20,
                          paddingHorizontal: 10,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ec5454',
                            width: 60,
                            height: 60,
                            borderRadius: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            style={{ width: 40, height: 40 }}
                            src={require('../../assets/images/alert.png')}
                            alt='alert'
                          />
                        </div>
                        <div style={{ flex: 1, marginLeft: 20 }}>
                          <p style={{ fontSize: 15 }}>
                            <span style={{ fontWeight: 'bold' }}>
                              {alert?.sender?.username}
                            </span>{' '}
                            a besoin de votre aide pour un cas urgent !
                          </p>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 15,
                            }}
                          >
                            Salle: {alert.salle}
                          </p>
                          <p style={{ color: '#9e9c9c' }}>
                            {moment(alert?.creation_date).format('HH:mm ')}
                            le
                            {moment(alert?.creation_date).format(
                              '  DD MMMM YYYY'
                            )}
                          </p>
                        </div>
                      </DropdownItem>
                    ))}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <button
                      style={{
                        borderWidth: 0,
                        borderRadius: 5,
                        padding: 5,
                        borderColor: '#566588',
                        backgroundColor: 'transparent',
                      }}
                      onClick={() => {
                        navigate('/alertes');
                        setAlertDropdown(false);
                      }}
                    >
                      <p style={{ color: '#566588' }}>
                        Afficher plus d'alertes
                      </p>
                    </button>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </NavItem> */}
            {/* <NavItem>
              <Dropdown
                isOpen={notificationDropdown}
                toggle={() => {
                  setNotificationDropdown(!notificationDropdown);
                }}
              >
                <DropdownToggle
                  nav
                  style={{ position: 'relative', marginRight: '1rem' }}
                >
                  <Badge
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      left: '-3px',
                      zIndex: '1',
                    }}
                    color='danger'
                    pill
                  >
                    0
                  </Badge>

                  <img
                    style={{ width: '25px', height: '25px' }}
                    src={require('../../assets/images/notifications.png')}
                    alt='notifications'
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <></>
                  {notifications &&
                    notifications.map((notif, index) => (
                      <DropdownItem
                        key={index}
                        style={{
                          paddingVertical: 20,
                          paddingHorizontal: 10,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#00b7f3',
                            width: 60,
                            height: 60,
                            borderRadius: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            style={{ width: 40, height: 40 }}
                            src={require('../../assets/images/icons8-calendrier-google-64.png')}
                            alt='alert'
                          />
                        </div>
                        <div style={{ flex: 1, marginLeft: 20 }}>
                          <p style={{ fontSize: 15 }}>
                            Cette activité commence maintenant !
                          </p>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 15,
                            }}
                          >
                            Nom de l'activité: {notif?.activityName}
                          </p>
                          <p style={{ color: '#9e9c9c' }}>
                            {moment(notif?.creation_date).format('HH:mm ')}
                            le
                            {moment(notif?.creation_date).format(
                              '  DD MMMM YYYY'
                            )}
                          </p>
                        </div>
                      </DropdownItem>
                    ))}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <button
                      style={{
                        borderWidth: 0,
                        borderRadius: 5,
                        padding: 5,
                        borderColor: '#566588',
                        backgroundColor: 'transparent',
                      }}
                      onClick={() => {
                        navigate('/alertes');
                        setNotificationDropdown(false);
                      }}
                    >
                      <p style={{ color: '#566588' }}>
                        Afficher plus de notifications
                      </p>
                    </button>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </NavItem> */}
            <NavItem>
              <Dropdown
                isOpen={compteDropdown}
                toggle={() => {
                  setCompteDropdown(!compteDropdown);
                }}
              >
                <DropdownToggle
                  nav
                  style={{
                    position: 'relative',
                    marginRight: '1rem',
                  }}
                >
                  <img
                    style={{ width: '25px', height: '25px' }}
                    src={require('../../assets/images/compte.png')}
                    alt='compte'
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {user && (
                    <DropdownItem
                      onClick={() => {
                        navigate('/compte');
                        setCompteDropdown(false);
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '4rem',
                      }}
                    >
                      <img
                        className={classes.profileImg}
                        src={
                          user?.profileImg
                            ? process.env.REACT_APP_APIURL + user?.profileImg
                            : require('../../assets/images/profile.png')
                        }
                        alt='profileImg'
                      />
                      <div className={classes.userContainer}>
                        <p className={classes.userName}>{user?.username}</p>
                        <p className={classes.userRoleDropdown}>
                          {' '}
                          {user?.role}
                        </p>
                      </div>
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => {
                      navigate('/compte');
                      setCompteDropdown(false);
                    }}
                  >
                    Modifier votre profil
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => {
                      navigate('/login');
                      logoutHandler();
                      setCompteDropdown(false);
                    }}
                  >
                    Se déconnecter
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Topbar;
