import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const TeamItem = (props) => {
  const { team } = props;

  return (
    <FormControlLabel
      value={team.id}
      control={<Radio style={{ color: '#c5a47e' }} />}
      label={team.name}
    />
  );
};

export default TeamItem;
