import React from "react";
import classes from "../AddActivityModal/AddActivityModal.module.css";
import axios from "../../utils/axios";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Menu, MenuItem, TextField } from "@mui/material";
import Swal from "sweetalert2";

const EditActivityModal = (props) => {
  const { toggle, modal, refresh, setRefresh, user, event, toggleModal } =
    props;
  const [teams, setTeams] = React.useState();
  const [eventData, setEventData] = React.useState(event.activity);

  React.useEffect(() => {
    axios
      .get(`/team/`)
      .then((res) => {
        setTeams(res.data.teams);
        console.log(res.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onEdit = () => {
    axios
      .put("/activity/edit", {
        activity_id: eventData.id,
        date: eventData.date,
        duration: eventData.duration,
        team_id: eventData.team_id,
        name: eventData.name,
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Activity Changed Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setRefresh(!refresh);
        toggleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" centered scrollable>
      <ModalHeader toggle={toggle}>Edit event</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col>
              <TextField
                required
                style={{ width: "100%" }}
                name="name"
                type="string"
                variant="filled"
                label="Name"
                value={eventData.name}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row className={classes.rowContainer}>
            <Col>
              <TextField
                required
                style={{ width: "100%" }}
                name="date"
                type="Date"
                variant="filled"
                label="Date"
                value={eventData.date}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <TextField
                required
                style={{ width: "100%" }}
                name="duration"
                type="number"
                variant="filled"
                label="Duration"
                value={eventData.duration}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <Row className={classes.rowContainer}>
            <Col>
              <TextField
                style={{ width: "100%" }}
                name="team_id"
                select
                label="Teams"
                value={eventData.team_id}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    [e.target.name]: e.target.value,
                  });
                }}
                variant="filled"
              >
                {teams?.map((team) => {
                  return <MenuItem value={team.id}>{team.name}</MenuItem>;
                })}
              </TextField>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          // color='primary'
          onClick={() => {
            toggle();
            onEdit();
          }}
          style={{ backgroundColor: "#6DCDFF", border: "none" }}
        >
          Edit
        </Button>
        <Button
          onClick={toggle}
          style={{
            backgroundColor: "#DCDCDC",
            border: "none",
            color: "#717171",
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditActivityModal;
