import React from 'react';
import classes from './Chart.module.css';
import { Col, Row } from 'reactstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const Chart = (props) => {
  const { phChartDataset } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div className={classes.chartContainer}>
      <Row className={classes.chartTitleContainer}>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <h6>{props.title}</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          {phChartDataset && <Line options={options} data={phChartDataset} />}
        </Col>
      </Row>
    </div>
  );
};

export default Chart;
