import React, { useEffect } from "react";
import classes from "./UserDetails.module.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import * as moment from "moment";
import "moment/locale/fr";

import { styled } from "@mui/material/styles";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { MenuItem, TextField } from "@mui/material";
import Swal from "sweetalert2";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#00CE8A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF2D55" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const UserDetails = () => {
  let { userId } = useParams();
  let navigate = useNavigate();
  const [user, setUser] = React.useState(null);
  const [activities, setActivities] = React.useState(null);
  const [commingActivities, setCommingActivities] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [previleges, setPrevileges] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [allProfiles, setAllProfiles] = React.useState();

  const editPrevileges = () => {
    console.log(previleges);
    axios
      .put("/auth/edit_user_previleges", {
        previleges: previleges,
        user_id: userId,
        role: user?.profile_id,
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Previleges changed successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setRefresh(!refresh);
      })
      .catch((err) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: true,
          timer: 1500,
        });
        console.log(err);
      });
  };
  const toggle = () => {
    setModal(!modal);
  };
  React.useEffect(() => {
    axios
      .get("/auth/users?id=" + userId)
      .then((res) => {
        setUser(res.data.user);
        console.log(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/profile/`)
      .then((res) => {
        setAllProfiles(res.data.profiles);
        console.log(res.data.profiles);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  React.useEffect(() => {
    const today = new Date();
    let tempP = [];
    user?.previleges &&
      user.previleges.forEach((previlege) => {
        tempP.push(previlege.id);
      });
    setPrevileges(tempP);

    user?.team_id &&
      axios
        .get(
          "/activity/?fromdate=" +
            today.getFullYear() +
            "-01-01&team_id=" +
            user?.team_id
        )
        .then((res) => {
          let temp2 = res.data.activities;
          temp2.sort(function (b, a) {
            a = new Date(
              moment(a?.date.concat(" ", a.time)) + 1 * 60 * 60 * 1000
            );
            b = new Date(
              moment(b.date.concat(" ", b.time)) + 1 * 60 * 60 * 1000
            );
            return a > b ? -1 : a < b ? 1 : 0;
          });
          var historique = [];
          var future = [];
          temp2.forEach((activity) => {
            if (activity.status === "done") {
              historique.push(activity);
            } else if (
              activity.status === "waiting" ||
              activity.status === "accepted"
            ) {
              future.push(activity);
            }
          });
          setCommingActivities(future);
          setActivities(historique);
          // console.log(temp2);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [user, refresh]);

  return (
    <div className={classes.mainContainer}>
      {user && (
        <Container fluid className={classes.activityDetails}>
          <Modal isOpen={modal} toggle={toggle} size="lg" centered scrollable>
            <ModalHeader toggle={toggle}>Edit account previleges</ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row>
                  <TextField
                    style={{ width: "100%", marginBottom: "15px" }}
                    id="role_input"
                    select
                    required
                    label="Role"
                    value={user?.profile_id}
                    onChange={(e) => {
                      let profileTemp = allProfiles.filter(
                        (profile) => profile.id === e.target.value
                      )[0];

                      let listPrevs = [];
                      profileTemp.previleges.forEach((previlege) => {
                        listPrevs.push(previlege.id);
                      });

                      setUser({
                        ...user,
                        profile_id: e.target.value,
                        previleges: profileTemp.previleges,
                      });

                      setPrevileges(listPrevs);
                      setRole(true);
                    }}
                    variant="filled"
                  >
                    {allProfiles &&
                      allProfiles.map((profile) => {
                        return (
                          <MenuItem key={profile.id} value={profile.id}>
                            {profile.name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                  {allProfiles
                    ?.find((prof) => prof.id === user?.profile_id)
                    ?.previleges?.map((previlege) => {
                      return (
                        <>
                          {!role ? (
                            <Col xs="12" md="4" lg="4" key={previlege?.id}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    defaultChecked={
                                      user?.previleges?.find(
                                        (p) => p.id === previlege.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setPrevileges((previleges) => [
                                          ...previleges,
                                          previlege.id,
                                        ]);
                                      } else {
                                        const temp = previleges.filter(
                                          (previlegeId) =>
                                            previlegeId !== previlege.id
                                        );
                                        setPrevileges(temp);
                                      }
                                    }}
                                  />
                                }
                                label={previlege?.description}
                              />
                            </Col>
                          ) : (
                            <Col xs="12" md="4" lg="4" key={previlege?.id}>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    defaultChecked={true}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setPrevileges((previleges) => [
                                          ...previleges,
                                          previlege.id,
                                        ]);
                                      } else {
                                        const temp = previleges.filter(
                                          (previlegeId) =>
                                            previlegeId !== previlege.id
                                        );
                                        setPrevileges(temp);
                                      }
                                    }}
                                  />
                                }
                                label={previlege?.description}
                              />
                            </Col>
                          )}
                        </>
                      );
                    })}
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={toggle}
                style={{
                  backgroundColor: "#DCDCDC",
                  border: "none",
                  color: "#717171",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  editPrevileges();
                  toggle();
                }}
                style={{
                  backgroundColor: "#6DCDFF",
                  border: "none",
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </Modal>
          <Row className={classes.activityDetailsTitle}>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginRight: "0.5rem",
                }}
                src={
                  user.profileImg
                    ? process.env.REACT_APP_APIURL + user.profileImg
                    : require("../../assets/images/profile.png")
                }
                alt="profile"
              />
              <h5 style={{ marginBottom: "0px" }}>
                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}{" "}
                {user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}
              </h5>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Type d'accès:{" "}
                <span className={classes.detailsContent}>{user.role}</span>
              </p>
            </Col>
            <Col>
              <p className={classes.detailsTitle}>
                E-mail :{" "}
                <span className={classes.detailsContent}>{user.email}</span>
              </p>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Date d'inscription:{" "}
                <span className={classes.detailsContent}>
                  {user.creation_date ? user.creation_date : "22-Mar-2022"}
                </span>
              </p>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button className={classes.editButtons} onClick={toggle}>
                <img
                  src={require("../../assets/images/editColored.png")}
                  alt="listMode"
                  style={{ width: "25px", height: "25px" }}
                />
              </button>
            </Col>
          </Row>

          <Row className={classes.rowHistoryContainer}>
            <Col xs="6" className={classes.colHistory}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0px" }}>Events history</h6>
                </Col>
              </Row>
              <Row className={classes.historiqueContainer}>
                <Col>
                  <Timeline>
                    {activities &&
                      activities.map((activity, index) => {
                        if (index !== activities.length - 1) {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {activities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>

                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          "/interventions/".concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require("../../assets/images/detailsLink.png")}
                                        alt="details"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        } else {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {activities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          "/interventions/".concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require("../../assets/images/detailsLink.png")}
                                        alt="details"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>

                                <p style={{ color: "#8F9BB3" }}>
                                  {activity.activity_desc}
                                </p>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      })}
                  </Timeline>
                </Col>
              </Row>
            </Col>
            <Col xs="6" className={classes.colHistory}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0px" }}>Upcoming events</h6>
                </Col>
              </Row>
              <Row className={classes.historiqueContainer}>
                <Col>
                  <Timeline>
                    {commingActivities &&
                      commingActivities.map((activity, index) => {
                        if (index !== commingActivities.length - 1) {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {commingActivities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>

                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          "/interventions/".concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require("../../assets/images/detailsLink.png")}
                                        alt="details"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        } else {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {commingActivities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          "/interventions/".concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require("../../assets/images/detailsLink.png")}
                                        alt="details"
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      })}
                  </Timeline>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default UserDetails;
