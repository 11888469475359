import React from 'react';
import axios from '../../utils/axios';
import classes from './Teams.module.css';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import GroupItem from './TeamItem/TeamItem';
import Swal from 'sweetalert2';

const Teams = () => {
  const [teams, setTeams] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [perPage, setPerPage] = React.useState(10);
  const [paginate, setPaginate] = React.useState(false);
  const [teamData, setTeamData] = React.useState({
    name: '',
  });
  const [refresh, setRefresh] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [allTeams, setAllTeams] = React.useState();
  const [pageTeams, setPageTeams] = React.useState();
  const toggle = () => {
    setModal(!modal);
  };

  React.useEffect(() => {
    axios
      .get('/team/?page=' + pageCount + '&per_page=' + perPage)
      .then((res) => {
        setTeams(res.data.teams);
        setPageTeams(res.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, refresh, paginate]);

  React.useEffect(() => {
    axios
      .get('/team/')
      .then((res) => {
        setAllTeams(res.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const searchGroup = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredTeams = allTeams.filter((group) => {
        return group.name.toLowerCase().includes(search.toLowerCase());
      });
      setTeams(filteredTeams);
    } else {
      setTeams(pageTeams);
    }
  };

  const addTeam = () => {
    if (teamData.name) {
      axios
        .post(`/team/create`, {
          name: teamData.name,
        })
        .then((res) => {
          console.log(res.data);
          setRefresh(!refresh);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Team created successfully',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Please try again',
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Modal isOpen={modal} toggle={toggle} size='lg' centered scrollable>
        <ModalHeader toggle={toggle}>Add Team</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col xs='12' md='12' lg='12'>
                <p className={classes.inputLabel}>Name:</p>
                <TextField
                  value={teamData?.name}
                  onChange={(e) =>
                    setTeamData({ ...teamData, name: e.target.value })
                  }
                  required
                  style={{ width: '100%' }}
                  id='nom_input'
                  variant='outlined'
                  size='small'
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              addTeam();
              toggle();
            }}
            style={{ backgroundColor: '#6DCDFF', border: 'none' }}
          >
            Add
          </Button>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: '#DCDCDC',
              border: 'none',
              color: '#717171',
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className={classes.optionMenu}>
        <div>
          <button className={classes.modeItems}>
            <img
              src={require('../../assets/images/listMode.png')}
              alt='listMode'
              style={{ width: '15px', height: '10px' }}
            />
          </button>
          <button className={classes.modeList}>
            <img
              src={require('../../assets/images/modeItems.png')}
              alt='listMode'
              style={{ width: '15px', height: '15px' }}
            />
          </button>
        </div>
        <button className={classes.addButton} onClick={toggle}>
          Add
        </button>
      </div>

      <div className={classes.tableContainer}>
        <table>
          <thead style={{ backgroundColor: 'white', color: '#222B45' }}>
            <tr>
              <th colSpan='3'>Teams list</th>

              <th style={{ width: '250px' }}>
                <div
                  style={{
                    position: 'relative',
                    width: '90%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type='text'
                    placeholder='Search'
                    onChange={searchGroup}
                    className={classes.searchInput}
                  />
                  <img
                    src={require('../../assets/images/search.png')}
                    alt='search'
                    style={{
                      width: '30px',
                      height: '30px',
                      position: 'absolute',
                      right: '10px',
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Team Name</th>

              <th>Creation date</th>
              <th>Number of members</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {teams &&
              teams.map((team, index) => (
                <GroupItem
                  key={index}
                  group={team}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>
                <button
                  className={classes.pagintionNextPrevButton}
                  onClick={() => {
                    if (pageCount - 1 > 0) {
                      setPageCount(pageCount - 1);
                    }
                  }}
                >
                  <img
                    style={{
                      height: '14px',
                      width: '16px',
                      marginRight: '0.5em',
                    }}
                    src={require('../../assets/images/prev.png')}
                    alt='previous'
                  />
                  Prev
                </button>
              </th>
              <th
                style={{
                  display: 'flex',
                }}
              >
                <p>Per page:</p>
                <input
                  type='number'
                  value={perPage}
                  onChange={(e) => setPerPage(e.target.value)}
                  className={classes.paginationInput}
                />
                <button
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                  onClick={() => {
                    if (perPage > 0) {
                      setPaginate(!paginate);
                    }
                  }}
                >
                  <img
                    style={{
                      height: '26px',
                      width: '26px',
                    }}
                    src={require('../../assets/images/check.png')}
                    alt='previous'
                  />
                </button>
              </th>
              <th style={{ padding: '0px' }}>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button className={classes.pagintionButton}>
                    {pageCount}
                  </button>
                  <button
                    className={classes.pagintionButtonNxt}
                    onClick={() => {
                      if (teams.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    {pageCount + 1}
                  </button>
                </div>
              </th>

              <th>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '2em',
                  }}
                >
                  <button
                    className={classes.pagintionNextPrevButton}
                    onClick={() => {
                      if (teams.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    <img
                      style={{
                        height: '14px',
                        width: '16px',
                        marginRight: '0.5em',
                      }}
                      src={require('../../assets/images/next.png')}
                      alt='previous'
                    />
                    Next
                  </button>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
export default Teams;
