import React from "react";
import classes from "./SideBar.module.css";

import { NavItem, Nav } from "reactstrap";
import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import dashLogo from "../../assets/images/dash.svg";
import userIcon from "../../assets/images/userIcon.svg";
import teamIcon from "../../assets/images/teamIcon.svg";
// import compteIcon from '../../assets/images/compteIcon.svg';
import clientIcon from "../../assets/images/clients.svg";
import interventionIcon from "../../assets/images/interventionIcon.svg";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import alertIcon from "../../assets/images/alert.svg";
const SideBar = ({ isOpen, toggle }) => {
  const { user } = React.useContext(GlobalContext);
  React.useEffect(() => {
    // console.log(user?.previleges.find((v) => v.name === 'GUSER') !== undefined);
  }, [user]);
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      {/* <div className='sidebar-header'>
      <span color='info' onClick={toggle}>
        &times;
      </span>
    </div> */}
      <div className={classes.logoContainer}>
        <img
          style={{ height: "38px", width: "117px" }}
          src={require("../../assets/images/Logo.png")}
          alt="Logo"
        />
      </div>
      <div className={classes.userDetailsContainer}>
        <img
          className={classes.profileImg}
          src={
            user?.profileImg
              ? process.env.REACT_APP_APIURL + user?.profileImg
              : require("../../assets/images/profile.png")
          }
          alt="Logo"
        />
        <div className={classes.userContainer}>
          <p className={classes.userName}>{user?.username}</p>
          <p className={classes.userRole}> {user?.role}</p>
        </div>
      </div>

      <div className="side-menu" style={{ flexGrow: "1" }}>
        <Nav
          vertical
          className="list-unstyled pb-3 "
          style={{
            display: "flex",
            // height: '700px',
            justifyContent: "space-between",
          }}
        >
          <div>
            <NavItem className={classes.navItem}>
              <NavLink
                tag={Link}
                to={"/"}
                className={(navData) =>
                  navData.isActive ? classes.focused : ""
                }
              >
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "16px",
                  }}
                  src={dashLogo}
                  alt="dashbord icon"
                />
                My Account
              </NavLink>
            </NavItem>

            {user?.previleges.find((v) => v.name === "GUSER") !== undefined && (
              <NavItem className={classes.navItem}>
                <NavLink
                  tag={Link}
                  to={"/utilisateurs"}
                  className={(navData) =>
                    navData.isActive ? classes.focused : ""
                  }
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "16px",
                    }}
                    src={userIcon}
                    alt="userIcon"
                  />
                  Users
                </NavLink>
              </NavItem>
            )}
            {user?.previleges.find((v) => v.name === "GCLIENT") !==
              undefined && (
              <NavItem className={classes.navItem}>
                <NavLink
                  tag={Link}
                  to={"/clients"}
                  className={(navData) =>
                    navData.isActive ? classes.focused : ""
                  }
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "16px",
                    }}
                    src={clientIcon}
                    alt="clientIcon"
                  />
                  Clients
                </NavLink>
              </NavItem>
            )}
            {user?.previleges.find((v) => v.name === "GTEAM") !== undefined && (
              <NavItem className={classes.navItem}>
                <NavLink
                  tag={Link}
                  to={"/teams"}
                  className={(navData) =>
                    navData.isActive ? classes.focused : ""
                  }
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "16px",
                    }}
                    src={teamIcon}
                    alt="teamIcon"
                  />
                  Teams
                </NavLink>
              </NavItem>
            )}
            {user?.role !== "client" && (
              <NavItem className={classes.navItem}>
                <NavLink
                  tag={Link}
                  to={"/interventions"}
                  className={(navData) =>
                    navData.isActive ? classes.focused : ""
                  }
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "16px",
                    }}
                    src={interventionIcon}
                    alt="interventionIcon"
                  />
                  Interventions
                </NavLink>
              </NavItem>
            )}
            {user?.previleges.find((v) => v.name === "SELECTC") !==
              undefined && (
              <NavItem className={classes.navItem}>
                <NavLink
                  tag={Link}
                  to={"/calendrier"}
                  className={(navData) =>
                    navData.isActive ? classes.focused : ""
                  }
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "16px",
                    }}
                    src={calendarIcon}
                    alt="calendarIcon"
                  />
                  Calendar
                </NavLink>
              </NavItem>
            )}
            {user?.previleges.find((v) => v.name === "VIEWALERT") !==
              undefined && (
              <NavItem className={classes.navItem}>
                <NavLink
                  tag={Link}
                  to={"/alerts"}
                  className={(navData) =>
                    navData.isActive ? classes.focused : ""
                  }
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "16px",
                    }}
                    src={alertIcon}
                    alt="dashbord icon"
                  />
                  Alerts
                </NavLink>
              </NavItem>
            )}
          </div>
          {/* <div>
            <NavItem className={classes.navItem}>
              <NavLink
                tag={Link}
                to={'/compte'}
                className={(navData) =>
                  navData.isActive ? classes.focused : ''
                }
              >
                <img
                  style={{ height: '20px', width: '20px', marginRight: '16px' }}
                  src={compteIcon}
                  alt='compteIcon'
                />
                Compte
              </NavLink>
            </NavItem>
          </div> */}
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
