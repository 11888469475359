import React, { useEffect } from "react";
import axios from "../../utils/axios";
import localStorageService from "../../utils/localStorageService";
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.css";
import { Row, Col, Container } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import GlobalContext from "../../contexts/GlobalContext";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";

const Login = () => {
  const { refresh, setRefresh } = React.useContext(GlobalContext);

  const [checked, setChecked] = React.useState(true);
  const [username, setUsername] = React.useState(
    localStorage.getItem("username") ? localStorage.getItem("username") : ""
  );
  const [password, setPassword] = React.useState("");
  const [hidden, setHidden] = React.useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  let navigate = useNavigate();
  const loginHandler = (event) => {
    event.preventDefault();
    if (username && password) {
      axios
        .post("/auth/login", {
          password: password,
          username: username,
        })
        .then((res) => {
          localStorageService.setToken(res?.data?.user?.access);
          localStorageService.setRefreshToken(res?.data?.user?.refresh);
          setRefresh(!refresh);
          navigate("/");
          if (checked) {
            localStorage.setItem("username", username);
            localStorage.setItem(
              "full_name",
              res?.data?.user?.first_name + " " + res?.data?.user?.last_name
            );
          } else {
            localStorage.removeItem("username");
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response?.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Veuillez entrer le nom d'utilisateur et le mot de passe!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    const token = localStorageService.getAccessToken();
    if (token) {
      navigate("/compte");
    }
  }, []);

  return (
    <Container fluid className={classes.mainContainer}>
      <Row
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col xs="10" md="10" lg="5" className={classes.loginContainer}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#bedde6",
                    paddingBlock: "1rem",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <img
                    src={require("../../assets/images/Logo.png")}
                    alt="Logo"
                    style={{ width: "35%" }}
                  />
                </Col>
              </Row>
              <form onSubmit={loginHandler} className={classes.loginForm}>
                <div className={classes.loginFormTitle}>
                  <h3>Login to continue</h3>
                </div>
                <div className={classes.loginFormInput}>
                  <TextField
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                    className={classes.inputField}
                    id="username_input"
                    variant="filled"
                    margin="normal"
                  />
                </div>
                <div
                  className={classes.loginFormInput}
                  style={{
                    display: "flex",
                    alignItems: "center",

                    width: "100%",
                  }}
                >
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    style={{ width: "87%" }}
                    type={hidden ? "password" : "text"}
                    required
                    id="password_input"
                    variant="filled"
                    margin="normal"
                  />
                  <InputAdornment position="end" style={{ marginTop: "10px" }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setHidden(!hidden);
                      }}
                      edge="end"
                    >
                      {hidden ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    style={{ color: "#2096bb" }}
                  />
                  <p>Remember me</p>
                </div>
                <div className={classes.loginFormInput}>
                  <button
                    type="submit"
                    className={classes.loginFormButton}
                    // onClick={loginHandler}
                  >
                    Login
                  </button>
                </div>
                <div style={{ marginTop: "0.5em" }}>
                  <p style={{ color: "#566588" }}>Forgot your password?</p>
                </div>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default Login;
