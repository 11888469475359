import React from "react";
import axios from "../../../utils/axios";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#00CE8A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF2D55" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const UserItem = (props) => {
  let navigate = useNavigate();
  // const { refresh, setRefresh } = props;
  const [active, setActive] = React.useState(props.user.active);
  const activeStatusChange = (status) => {
    let temp = status.toString();
    let activeStatus = temp.charAt(0).toUpperCase() + temp.slice(1);
    axios
      .put(`/user/active`, {
        status: activeStatus,
        user_id: props.user.id,
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status du compte utilisateur changé avec succès!",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <tr>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
            src={
              props.user?.profileImg
                ? process.env.REACT_APP_APIURL + props.user?.profileImg
                : require("../../../assets/images/profile.png")
            }
            alt="profile"
          />

          <div style={{ marginLeft: "1em" }}>
            <p>{props.user?.username}</p>
            <p style={{ color: "#8F9BB3" }}>{props.user?.email}</p>
          </div>
        </div>
      </td>
      <td>{props.user?.lastName + " " + props.user?.name}</td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: "1em" }}>
            <p>
              {props.user?.address} {props.user?.city}{" "}
            </p>
            <p style={{ color: "#8F9BB3" }}>
              {props.user?.region}, {props.user?.country}
            </p>
          </div>
        </div>
      </td>
      <td>{props.user?.mobile}</td>

      <td>{props.user?.creation_date}</td>
      <td>
        <div>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              navigate("/clients/".concat(props.user?.id));
            }}
          >
            <img
              src={require("../../../assets/images/detailsLink.png")}
              alt="details"
              style={{ width: "25px", height: "25px", marginRight: "1rem" }}
            />
          </button>
          {/* <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={deleteHandler}
          >
            <img
              src={require('../../../assets/images/delete.png')}
              alt='listMode'
              style={{ width: '17px', height: '20px' }}
            />
          </button> */}
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} />}
            label=""
            checked={active}
            onChange={(e) => {
              setActive(e.target.checked);
              activeStatusChange(e.target.checked);
            }}
          />
        </div>
      </td>
    </tr>
  );
};
export default UserItem;
