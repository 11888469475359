import React from 'react';
import { useNavigate } from 'react-router-dom';

const InterventionItem = (props) => {
  // const { refresh, setRefresh } = props;
  let navigate = useNavigate();

  return (
    <tr>
      <td>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              backgroundColor:
                props.intervention.event_status === 'waiting'
                  ? '#FF9D2B'
                  : props.intervention.event_status === 'accepted'
                  ? '#00E096'
                  : props.intervention.event_status === 'done'
                  ? '#0095FF'
                  : '#FF2D55',

              width: '20px',
              height: '20px',
              borderRadius: '50%',
              marginRight: '0.5em',
            }}
          ></div>
          <p>{props.intervention.event_status}</p>
        </div>
      </td>
      <td>{props.intervention.event_date} </td>
      <td>{props.intervention?.pool_name}</td>
      <td>{props.intervention?.team_name}</td>

      <td>
        {props.intervention.client_name} {props.intervention.client_lastname}
      </td>

      <td>
        <div>
          <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={() => {
              navigate('/interventions/'.concat(props.intervention?.id));
            }}
          >
            <img
              src={require('../../../assets/images/detailsLink.png')}
              alt='details'
              style={{ width: '25px', height: '25px', marginRight: '1rem' }}
            />
          </button>
        </div>
      </td>
    </tr>
  );
};
export default InterventionItem;
