import React from "react";
import axios from "../../utils/axios";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import { Container, Row, Col, Button } from "reactstrap";
import classes from "./Intervention.module.css";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import downArrow from "../../assets/images/down-arrow.svg";
import Chart from "./../../components/Chart/Chart";
import GlobalContext from "../../contexts/GlobalContext";
import { CSVLink } from "react-csv";
import { useEffect } from "react";

const Intervention = () => {
  const { user } = React.useContext(GlobalContext);
  let { interventionId } = useParams();
  const [refresh, setRefresh] = React.useState(false);
  const [form, setForm] = React.useState();
  const [formData, setFormData] = React.useState("");
  const [interventions, setInterventions] = React.useState([]);
  const [interventionKeys, setInterventionKeys] = React.useState([]);
  const [interventionValues, setInterventionValues] = React.useState([]);

  const [productsKeys, setProductsKeys] = React.useState([]);
  const [productsValues, setProductsValues] = React.useState([]);

  const [closeProducts, setCloseProducts] = React.useState(false);
  const [closeAnalyses, setCloseAnalyses] = React.useState(false);
  const [phChartDataset, setPhChartDataset] = React.useState(null);
  const [chloreChartDataset, setChloreChartDataset] = React.useState(null);

  const updateSheet = () => {
    axios
      .put("/intervention/edit", {
        formData: JSON.stringify(formData),
        id: form.id,
      })
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Sheet updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error updating sheet",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    // console.log(JSON.stringify(formData));
  };

  React.useEffect(() => {
    axios
      .get("/intervention/?id=" + interventionId)
      .then((res) => {
        setForm(res.data.intervention[0]);
        axios
          .get(
            "/intervention/?last_month=true&pool_id=" +
              res.data.intervention[0].pool_id
          )
          .then((res) => {
            var temp = res.data.interventions.filter(
              (intervention) =>
                intervention?.formData !== null &&
                intervention?.formData !== '""'
            );
            temp.sort(function (a, b) {
              var key1 = new Date(a.event_date);
              var key2 = new Date(b.event_date);
              if (key1 < key2) {
                return -1;
              } else if (key1 === key2) {
                return 0;
              } else {
                return 1;
              }
            });
            setInterventions(temp);
            var keys = [];
            var values = [];
            var pKeys = [];
            var pValues = [];
            let dataset = [];
            let chloreDataset = [];
            temp.map((intervention) => {
              let ph = JSON.parse(intervention?.formData)?.ANNALYSES["PH"];
              let chlore = JSON.parse(intervention?.formData)?.ANNALYSES[
                "CHLORE LIBRE"
              ];
              if (ph) {
                dataset.push({
                  x: intervention.event_date,
                  y: ph,
                });
              }
              if (chlore) {
                chloreDataset.push({
                  x: intervention.event_date,
                  y: chlore,
                });
              }

              return true;
            });
            let tempDataset = {
              label: "PH",
              data: dataset,
              fill: false,
              borderColor: "#FF2D55",
              backgroundColor: "#FF2D55",
              tension: 0.1,
            };
            let tempDataset2 = {
              label: "CHLORE LIBRE",
              data: chloreDataset,
              fill: false,
              borderColor: "#FF9D2B",
              backgroundColor: "#FF9D2B",
              tension: 0.1,
            };
            setPhChartDataset({
              datasets: [tempDataset],
            });
            setChloreChartDataset({
              datasets: [tempDataset2],
            });
            //extract analyses keys
            temp.forEach((intervention) => {
              let x = JSON.parse(intervention?.uischema);
              let k = x?.ANNALYSES["ui:order"];
              k.forEach((key) => {
                if (!keys.includes(key)) {
                  keys.push(key);
                }
              });
            });
            //extract prodict keys
            temp.forEach((intervention) => {
              let x = JSON.parse(intervention.uischema);
              let k = x?.PRODUITS["ui:order"];
              k.forEach((key) => {
                if (!pKeys.includes(key)) {
                  pKeys.push(key);
                }
              });
            });
            //extract analyses values

            temp.forEach((intervention) => {
              console.log(intervention?.formData === null);

              var value = [];
              var x = JSON.parse(intervention?.formData);
              keys.forEach((key) => {
                value.push(x?.ANNALYSES[key]);
              });

              values.push(value);
            });
            //extract product values
            temp.forEach((intervention) => {
              var value = [];
              var x = JSON.parse(intervention?.formData);

              pKeys.forEach((key) => {
                value.push(x?.PRODUITS[key]);
              });

              pValues.push(value);
            });
            setInterventionKeys(keys);
            setInterventionValues(values);
            setProductsValues(pValues);
            setProductsKeys(pKeys);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    console.log(interventionValues);
  }, [interventionValues]);

  useEffect(() => {
    setFormData({
      ...formData,
      PRODUITS: {
        ...formData.PRODUITS,
        RESPONSABLE: localStorage.getItem("full_name"),
      },
    });
  }, []);

  return (
    <div className={classes.mainContainer}>
      <Container fluid>
        <Row style={{ marginBottom: "1rem" }}>
          {form && (
            <Col style={{ marginLeft: "1rem" }}>
              <p className={classes.interventionGeneralInfo}>
                Date: {form.event_date}
              </p>
              <p className={classes.interventionGeneralInfo}>
                Time: {form.event_time}
              </p>
              <p className={classes.interventionGeneralInfo}>
                Pool: {form.pool_name}
              </p>
              <p className={classes.interventionGeneralInfo}>
                Team: {form.team_name}
              </p>
            </Col>
          )}
          <Col xs="8" className={classes.saveBtnRow}>
            {user?.previleges.find((v) => v.name === "UPINT") && (
              <button className={classes.saveBtn} onClick={updateSheet}>
                Save Changes
              </button>
            )}
          </Col>
        </Row>

        <Row className={classes.formRow}>
          <Col xs="12" md="3">
            {form && (
              <Form
                schema={JSON.parse(form.schema)}
                uiSchema={JSON.parse(form.uischema)}
                onChange={(newFormData) => setFormData(newFormData.formData)}
                formData={formData ? formData : JSON.parse(form.formData)}
                children={true}
              />
            )}
          </Col>
          <Col xs="12" md="9" className={classes.previousInterventions}>
            <h4>Previous Interventions</h4>
            <hr />
            <Row>
              <Col xs="12" md="12" lg="6">
                <Chart phChartDataset={phChartDataset} title="PH" />
              </Col>
              <Col xs="12" md="12" lg="6">
                <Chart
                  phChartDataset={chloreChartDataset}
                  title="CHLORE LIBRE"
                />
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1.5rem",
              }}
            >
              <h4
                style={{
                  marginBottom: "0rem",
                }}
              >
                ANNALYSES
              </h4>

              <button
                style={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => setCloseAnalyses(!closeAnalyses)}
              >
                <img
                  style={{ width: "2rem", height: "2rem" }}
                  src={downArrow}
                  alt="down arrow"
                />
              </button>
            </div>

            <hr />
            {closeAnalyses && (
              <table className={classes.tablePreviousInterventions}>
                <thead>
                  <tr>
                    <th>Date</th>
                  </tr>
                  {interventionKeys &&
                    interventionKeys.map((key, index) => {
                      return (
                        <tr key={index}>
                          <th className={classes.tableRowsKeys}>{key}</th>
                        </tr>
                      );
                    })}
                </thead>
                <tbody>
                  <tr>
                    {interventions &&
                      interventions.map((intervention, index) => {
                        return (
                          <td style={{ fontWeight: "bold" }} key={index}>
                            {intervention.event_date}
                          </td>
                        );
                      })}
                  </tr>

                  {interventionKeys &&
                    interventionKeys.map((key, index) => {
                      return (
                        <tr key={index}>
                          {interventionValues &&
                            interventions.map((intervention, index) => {
                              var x = JSON.parse(intervention?.formData);

                              return (
                                <td key={index} className={classes.tableRows}>
                                  {x
                                    ? x?.ANNALYSES[key]
                                      ? x?.ANNALYSES[key]
                                      : "-"
                                    : "-"}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1.5rem",
              }}
            >
              <h4
                style={{
                  marginBottom: "0rem",
                }}
              >
                PRODUITS
              </h4>

              <button
                style={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => setCloseProducts(!closeProducts)}
              >
                <img
                  style={{ width: "2rem", height: "2rem" }}
                  src={downArrow}
                  alt="down arrow"
                />
              </button>
            </div>
            <hr />
            {closeProducts && (
              <table className={classes.tablePreviousInterventions}>
                <thead>
                  <tr>
                    <th>Date</th>
                  </tr>
                  {productsKeys &&
                    productsKeys.map((key, index) => {
                      return (
                        <tr key={index}>
                          <th className={classes.tableRowsKeys}>{key}</th>
                        </tr>
                      );
                    })}
                </thead>
                <tbody>
                  <tr>
                    {interventions &&
                      interventions.map((intervention, index) => {
                        return (
                          <td style={{ fontWeight: "bold" }} key={index}>
                            {intervention.event_date}
                          </td>
                        );
                      })}
                  </tr>

                  {productsKeys &&
                    productsKeys.map((key, index) => {
                      return (
                        <tr key={index} style={{ width: "100px" }}>
                          {productsValues &&
                            interventions.map((intervention, index) => {
                              var x = JSON.parse(intervention.formData);

                              return (
                                <td key={index} className={classes.tableRows}>
                                  {x
                                    ? x?.PRODUITS[key]
                                      ? x?.PRODUITS[key]
                                      : "-"
                                    : "-"}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Intervention;
