import React from "react";
import classes from "./ClientDetails.module.css";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import axios from "../../utils/axios";
import PoolItem from "./PoolItem/PoolItem";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";

import Crop75Icon from "@mui/icons-material/Crop75";
import PropTypes from "prop-types";
import Loader from "../../components/Loader/Loader";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <Crop75Icon fontSize="large" color="success" />,
    label: "Low",
  },
  2: {
    icon: <Crop75Icon fontSize="large" color="success" />,
    label: "Low",
  },
  3: {
    icon: <Crop75Icon fontSize="large" color="warning" />,
    label: "Medium",
  },
  4: {
    icon: <Crop75Icon fontSize="large" color="error" />,
    label: "High",
  },
  5: {
    icon: <Crop75Icon fontSize="large" color="error" />,
    label: "High",
  },
};
function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const ClientDetails = () => {
  let { userId } = useParams();
  const [user, setUser] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [pools, setPools] = React.useState(null);
  const [pagePools, setPagePools] = React.useState();
  const [poolModal, setPoolModal] = React.useState(false);
  const [poolData, setPoolData] = React.useState({
    name: "",
    address: "",
    city: "",
    country: "",
    region: "",
  });
  const [description, setDescription] = React.useState("");
  const [priority, setPriority] = React.useState("");
  const [emergencyPoolId, setEmergencyPoolId] = React.useState("");
  const [modalAlert, setModalAlert] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [modalUpload, setModalUpload] = React.useState(false);
  const [docs, setDocs] = React.useState(null);
  const [selectedPool, setSelectedPool] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const toggleUpload = () => {
    setModalUpload(!modalUpload);
  };
  const toggleAlert = () => {
    setModalAlert(!modalAlert);
  };
  const createAlert = () => {
    setLoader(true);
    if (description === "" || priority === "" || emergencyPoolId === "") {
      setLoader(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all fields",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios
        .post("/alert/create", {
          description: description,
          danger_degree: priority,
          pool_id: emergencyPoolId,
        })
        .then((res) => {
          // console.log(res.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Emergency alert sent successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          const alert_id = res.data.alert.id;
          console.log(alert_id);
          const formData = new FormData();
          if (file) {
            formData.append("file", file);
            axios
              .post("/upload/", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                // console.log(res.data);
                // console.log(alert_id);
                user &&
                  file &&
                  axios
                    .post("/docs/add", {
                      user_id: user.id,
                      alert_id: alert_id,
                      url: res.data.file_url,
                      fileName: file.name,
                    })
                    .then((res) => {
                      // console.log(res.data);
                      setLoader(false);
                      setRefresh(!refresh);
                    })
                    .catch((err) => {
                      setLoader(false);
                      console.log({ err });
                    });
              })
              .catch((err) => {
                setLoader(false);
                console.log({ err });
              });
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };
  const createPool = () => {
    if (
      poolData.name !== "" &&
      poolData.address !== "" &&
      poolData.city !== "" &&
      poolData.country !== "" &&
      poolData.region !== "" &&
      user?.id
    ) {
      axios
        .post(`/pool/create`, {
          name: poolData.name,
          address: poolData.address,
          city: poolData.city,
          country: poolData.country,
          region: poolData.region,
          client_id: user?.id,
        })
        .then((res) => {
          // console.log(res.data);
          setRefresh(!refresh);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Pool added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setPoolModal(!poolModal);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "un probleme s'est produit lors de la création d'un pool",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all required fields",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const togglePoolModal = () => {
    setPoolModal(!poolModal);
  };
  const searchPool = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredPools = user.pools.filter((pool) => {
        return pool.name.toLowerCase().includes(search.toLowerCase());
      });
      setPools(filteredPools);
    } else {
      setPools(pagePools);
    }
  };
  React.useEffect(() => {
    axios
      .get("/auth/users?id=" + userId)
      .then((res) => {
        setUser(res.data.user);
        // console.log(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/pool/?client_id=" + userId + "&page=" + pageCount)
      .then((res) => {
        setPools(res.data.pools);
        setPagePools(res.data.pools);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, refresh]);
  const uploadFileHandler = () => {
    setLoader(true);
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      axios
        .post("/upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res.data);

          user &&
            file &&
            selectedPool &&
            axios
              .post("/docs/add", {
                user_id: userId,
                pool_id: selectedPool,
                url: res.data.file_url,
                fileName: file.name,
              })
              .then((res) => {
                // console.log(res.data);
                setLoader(false);
                setRefresh(!refresh);
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Fichier uploadé avec succès",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((err) => {
                console.log({ err });
                setLoader(false);
              });
        })
        .catch((err) => {
          console.log({ err });
          setLoader(false);
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Veuillez selectionner un fichier",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const deleteFileHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This document will be no longer accessible!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("/docs/delete", {
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({
              id: id,
            }),
          })
          .then((res) => {
            console.log(res.data);
            setRefresh(!refresh);
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    });
  };
  React.useEffect(() => {
    axios
      .get("/docs/?user_id=" + userId)
      .then((res) => {
        setDocs(res.data.docs);
        // console.log(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <div className={classes.mainContainer}>
      <Modal isOpen={modalUpload} toggle={toggleUpload} centered scrollable>
        <ModalHeader toggle={toggleUpload}>Add Document </ModalHeader>
        <ModalBody>
          <Container fluid>
            <div style={{ marginBottom: "2rem" }}>
              <TextField
                style={{ width: "100%" }}
                id="pool_input"
                select
                label="Choose Pool"
                value={selectedPool}
                onChange={(e) => {
                  setSelectedPool(e.target.value);
                }}
                variant="filled"
              >
                {user?.pools &&
                  user.pools?.map((pool) => {
                    return (
                      <MenuItem key={pool.id} value={pool.id}>
                        {pool.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <label className={classes.customFileUpload}>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
                Ajouter Votre Document
                <img
                  src={require("../../assets/images/upload.png")}
                  alt="upload icon"
                  style={{ width: "30px", height: "30px", marginLeft: "1rem" }}
                />
              </label>
              {file && <p className={classes.nameFile}>{file.name}</p>}
            </div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggleUpload}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              uploadFileHandler();
              toggleUpload();
            }}
            style={{ backgroundColor: "#6DCDFF", border: "none" }}
          >
            Ajouter
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalAlert}
        toggle={toggleAlert}
        size="md"
        centered
        scrollable
      >
        <ModalHeader toggle={toggleAlert}>Create Emergency Alert</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <p className={classes.inputLabel}>Description: </p>
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="description_input"
                  variant="filled"
                  label="Emergency description"
                  multiline
                  maxRows={4}
                  minRows={4}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Col>
              <Col xs="12" md="12" lg="12">
                <p className={classes.inputLabel}>Priority:</p>
                <StyledRating
                  name="highlight-selected-only"
                  defaultValue={1}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  onChange={(e, value) => {
                    setPriority(customIcons[value].label);
                  }}
                  highlightSelectedOnly
                />
              </Col>
              <Col xs="12" md="12" lg="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <label className={classes.customFileUpload}>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                    Ajouter Votre Document
                    <img
                      src={require("../../assets/images/upload.png")}
                      alt="upload icon"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "1rem",
                      }}
                    />
                  </label>
                  {file && <p className={classes.nameFile}>{file.name}</p>}
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              createAlert();
              toggleAlert();
            }}
            style={{ backgroundColor: "#6DCDFF", border: "none" }}
          >
            Create
          </Button>
          <Button
            onClick={toggleAlert}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={poolModal}
        toggle={togglePoolModal}
        size="lg"
        centered
        scrollable
      >
        <ModalHeader toggle={togglePoolModal}>Add Pool</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="name_pool_input"
                  variant="filled"
                  label="Pool name"
                  value={poolData.name}
                  onChange={(e) =>
                    setPoolData({ ...poolData, name: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="pool_address_input"
                  variant="filled"
                  label="Address"
                  value={poolData.address}
                  onChange={(e) =>
                    setPoolData({ ...poolData, address: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="pool_country_input"
                  variant="filled"
                  label="Country"
                  value={poolData.country}
                  onChange={(e) =>
                    setPoolData({ ...poolData, country: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="pool_city_input"
                  variant="filled"
                  value={poolData.city}
                  label="City"
                  onChange={(e) =>
                    setPoolData({ ...poolData, city: e.target.value })
                  }
                />
              </Col>
            </Row>

            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="pool_region_input"
                  variant="filled"
                  label="Region"
                  value={poolData.region}
                  onChange={(e) =>
                    setPoolData({ ...poolData, region: e.target.value })
                  }
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              createPool();
            }}
            style={{
              backgroundColor: "#6DCDFF",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Add
          </Button>
          <Button
            onClick={togglePoolModal}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
              borderRadius: "10px",
            }}
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
      {user && (
        <Container fluid className={classes.activityDetails}>
          <Row className={classes.activityDetailsTitle}>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginRight: "0.5rem",
                }}
                src={
                  user.profileImg
                    ? process.env.REACT_APP_APIURL + user.profileImg
                    : require("../../assets/images/profile.png")
                }
                alt="profile"
              />
              <h5 style={{ marginBottom: "0px" }}>
                {user.name} {user.lastName}
              </h5>
            </Col>

            <Col style={{ display: "flex", alignItems: "center" }}>
              <p className={classes.detailsTitle}>
                Type d'accès:{" "}
                <span className={classes.detailsContent}>{user.role}</span>
              </p>
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <p className={classes.detailsTitle}>
                E-mail :{" "}
                <span className={classes.detailsContent}>{user.email}</span>
              </p>
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <p className={classes.detailsTitle}>
                Mobile:{" "}
                <span className={classes.detailsContent}>{user.mobile}</span>
              </p>
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <p className={classes.detailsTitle}>
                Date d'inscription:{" "}
                <span className={classes.detailsContent}>
                  {user.creation_date ? user.creation_date : "22-Mar-2022"}
                </span>
              </p>
            </Col>
          </Row>
          <Row className={classes.rowContainerDoc}>
            <Col>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0px" }}>Documents associés</h6>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <button onClick={toggleUpload} className={classes.addButton}>
                    <img
                      src={require("../../assets/images/add.png")}
                      alt="add icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </Col>
              </Row>
              <Row className={classes.docContainer}>
                <Col>
                  {docs?.map((doc, index) => {
                    return (
                      <Row className={classes.fileContainer} key={index}>
                        <Col style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={require("../../assets/images/file.png")}
                            alt="file icon"
                            style={{
                              width: "40px",
                              height: "40px",
                              marginRight: "1rem",
                            }}
                          />
                          <p>{doc.fileName}</p>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button className={classes.docButtons}>
                            <img
                              src={require("../../assets/images/downloadDoc.png")}
                              alt="details"
                              style={{
                                width: "25px",
                                height: "25px",
                              }}
                              onClick={() => {
                                window.open(
                                  process.env.REACT_APP_APIURL +
                                    doc.url.slice(1)
                                );
                              }}
                            />
                          </button>

                          <button
                            className={classes.docButtons}
                            onClick={() => {
                              deleteFileHandler(doc.id);
                            }}
                          >
                            <img
                              src={require("../../assets/images/deleteDoc.png")}
                              alt="listMode"
                              style={{ width: "28px", height: "28px" }}
                            />
                          </button>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
          {loader && <Loader />}
          <div className={classes.tableContainer}>
            <table>
              <thead style={{ backgroundColor: "white", color: "#222B45" }}>
                <tr>
                  <th colSpan="4">Pools list</th>

                  <th style={{ width: "250px" }}>
                    <div
                      style={{
                        position: "relative",
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={searchPool}
                        className={classes.searchInput}
                      />
                      <img
                        src={require("../../assets/images/search.png")}
                        alt="search"
                        style={{
                          width: "30px",
                          height: "30px",
                          position: "absolute",
                          right: "10px",
                        }}
                      />
                    </div>
                  </th>
                  <th style={{ width: "200px" }}>
                    <button
                      className={classes.addPollBtn}
                      onClick={togglePoolModal}
                    >
                      <img
                        src={require("../../assets/images/addWhite.png")}
                        alt="listMode"
                        style={{ width: "35px", height: "35px" }}
                      />{" "}
                      Add Pool
                    </button>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Pool Name</th>
                  <th>Address</th>
                  <th>Last intervention date</th>
                  <th>Sheet</th>
                  <th>Details</th>
                  <th>Add alert</th>
                </tr>
              </thead>
              <tbody>
                {pools &&
                  pools.map((pool, index) => (
                    <PoolItem
                      key={index}
                      pool={pool}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      toggleAlert={toggleAlert}
                      setEmergencyPoolId={setEmergencyPoolId}
                    />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    <button
                      className={classes.pagintionNextPrevButton}
                      onClick={() => {
                        if (pageCount - 1 > 0) {
                          setPageCount(pageCount - 1);
                        }
                      }}
                    >
                      <img
                        style={{
                          height: "14px",
                          width: "16px",
                          marginRight: "0.5em",
                        }}
                        src={require("../../assets/images/prev.png")}
                        alt="previous"
                      />
                      Prev
                    </button>
                  </th>
                  <th colSpan="3" style={{ padding: "0px" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button className={classes.pagintionButton}>
                        {pageCount}
                      </button>
                      <button
                        className={classes.pagintionButtonNxt}
                        onClick={() => {
                          if (pools.length > 0) {
                            setPageCount(pageCount + 1);
                          }
                        }}
                      >
                        {pageCount + 1}
                      </button>
                    </div>
                  </th>

                  <th colSpan="2">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "2em",
                      }}
                    >
                      <button
                        className={classes.pagintionNextPrevButton}
                        onClick={() => {
                          if (pools.length > 0) {
                            setPageCount(pageCount + 1);
                          }
                        }}
                      >
                        <img
                          style={{
                            height: "14px",
                            width: "16px",
                            marginRight: "0.5em",
                          }}
                          src={require("../../assets/images/next.png")}
                          alt="previous"
                        />
                        Next
                      </button>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ClientDetails;
