import React from 'react';
import { useNavigate } from 'react-router-dom';
import AlertGray from '../../../assets/images/alertGray.svg';
import Sheets from '../../../assets/images/sheets.svg';

// get last interventions by date
const getLastInterventions = (interventions) => {
  let lastInterventionDate = '';
  let lastIntervention = {};
  if (interventions) {
    interventions.forEach((intervention) => {
      if (intervention.event_date > lastInterventionDate) {
        lastInterventionDate = intervention.event_date;
        lastIntervention = intervention;
      }
    });
  }
  return lastIntervention;
};

const PoolItem = (props) => {
  let navigate = useNavigate();

  return (
    <tr>
      <td>{props.pool.name}</td>

      <td>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: '1em' }}>
            <p>
              {props.pool?.address} {props.pool?.city}{' '}
            </p>
            <p style={{ color: '#8F9BB3' }}>
              {props.pool?.region}, {props.pool?.country}
            </p>
          </div>
        </div>
      </td>
      <td>{getLastInterventions(props.pool.interventions)?.event_date}</td>
      <td>
        {getLastInterventions(props.pool.interventions)?.id && (
          <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={() => {
              // console.log(getLastInterventions(props.pool.interventions)?.id);
              navigate(
                '/interventions/'.concat(
                  getLastInterventions(props.pool.interventions)?.id
                )
              );
            }}
          >
            <img
              src={Sheets}
              alt='sheet'
              style={{ width: '25px', height: '25px', marginRight: '1rem' }}
            />
          </button>
        )}
      </td>
      <td>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => {
            navigate('/pool/'.concat(props.pool.id));
          }}
        >
          <img
            src={require('../../../assets/images/detailsLink.png')}
            alt='details'
            style={{ width: '25px', height: '25px', marginRight: '1rem' }}
          />
        </button>
      </td>
      <td>
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          onClick={() => {
            props.setEmergencyPoolId(props.pool.id);
            props.toggleAlert();
          }}
        >
          <img
            src={AlertGray}
            alt='details'
            style={{ width: '25px', height: '25px', marginRight: '1rem' }}
          />
        </button>
      </td>
    </tr>
  );
};
export default PoolItem;
