import React from "react";
import axios from "../../utils/axios";
import classes from "./Users.module.css";
import UserItem from "./userItem/UserItem";

import { styled } from "@mui/material/styles";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Swal from "sweetalert2";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#00CE8A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF2D55" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Users = () => {
  const [users, setUsers] = React.useState();
  const [allUsers, setAllUsers] = React.useState();
  const [pageUsers, setPageUsers] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [refresh, setRefresh] = React.useState(false);
  const [allProfiles, setAllProfiles] = React.useState();
  const [selectedProfile, setSelectedProfile] = React.useState();
  const [previleges, setPrevileges] = React.useState([]);
  const [perPage, setPerPage] = React.useState(10);
  const [paginate, setPaginate] = React.useState(false);
  const [userData, setUserData] = React.useState({
    nom: "",
    prenom: "",
    email: "",
    mobile: "",
    role: "",
    username: "",
    password: "",
    address: "",
    country: "",
    region: "",
    city: "",
    confirmPassword: "",
  });
  const toggle = () => {
    setModal(!modal);
  };
  React.useEffect(() => {
    axios
      .get(`/auth/users?page=` + pageCount + "&per_page=" + perPage)
      .then((res) => {
        setUsers(res.data.users);
        setPageUsers(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageCount, refresh, paginate]);

  React.useEffect(() => {
    axios
      .get(`/auth/users`)
      .then((res) => {
        setAllUsers(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/profile/`)
      .then((res) => {
        setAllProfiles(res.data.profiles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);
  const createUser = () => {
    if (
      userData.email &&
      userData.nom &&
      userData.prenom &&
      userData.mobile &&
      userData.address &&
      userData.role &&
      userData.username &&
      userData.password
    ) {
      if (userData.password === userData.confirmPassword) {
        axios
          .post(`/auth/register`, {
            email: userData.email,
            name: userData.nom,
            lastName: userData.prenom,
            mobile: userData.mobile,
            address: userData.address,
            country: userData.country || "None",
            region: userData.region || "None",
            city: userData.city || "None",
            password: userData.password,
            profile_id: userData.role,
            username: userData.username,
            previleges: previleges,
          })
          .then((res) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "utilisateur crée avec succès",
              showConfirmButton: false,
              timer: 1500,
            });
            setRefresh(!refresh);
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: err.response.data.error,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Verify your password",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all fields",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const searchUser = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredUsers = allUsers.filter((user) => {
        const fullName = user.lastName + " " + user.name;
        return fullName.toLowerCase().includes(search.toLowerCase());
      });
      setUsers(filteredUsers);
    } else {
      setUsers(pageUsers);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Modal isOpen={modal} toggle={toggle} size="xl" centered scrollable>
        <ModalHeader toggle={toggle}>Add User</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.nom}
                  onChange={(e) =>
                    setUserData({ ...userData, nom: e.target.value })
                  }
                  required
                  style={{ width: "100%" }}
                  id="nom_input"
                  label="Name"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.prenom}
                  onChange={(e) =>
                    setUserData({ ...userData, prenom: e.target.value })
                  }
                  required
                  style={{ width: "100%" }}
                  id="prenom_input"
                  label="Last name"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.username}
                  onChange={(e) =>
                    setUserData({ ...userData, username: e.target.value })
                  }
                  required
                  style={{ width: "100%" }}
                  id="username_input"
                  label="Username"
                  variant="filled"
                />
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  required
                  style={{ width: "100%" }}
                  id="email_input"
                  label="E-mail"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.mobile}
                  onChange={(e) =>
                    setUserData({ ...userData, mobile: e.target.value })
                  }
                  style={{ width: "100%" }}
                  id="mobile_input"
                  label="Téléphone mobile"
                  variant="filled"
                  required
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  style={{ width: "100%" }}
                  id="role_input"
                  select
                  required
                  label="Choisir le rôle"
                  value={userData?.role}
                  onChange={(e) => {
                    setUserData({ ...userData, role: e.target.value });
                    let profileTemp = allProfiles.filter(
                      (profile) => profile.id === e.target.value
                    )[0];

                    setSelectedProfile(profileTemp);

                    let listPrevs = [];
                    profileTemp.previleges.forEach((previlege) => {
                      listPrevs.push(previlege.id);
                    });

                    setPrevileges(listPrevs);
                  }}
                  variant="filled"
                >
                  {allProfiles &&
                    allProfiles.map((profile) => {
                      return (
                        <MenuItem key={profile.id} value={profile.id}>
                          {profile.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                  required
                  style={{ width: "100%" }}
                  id="password_input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.confirmPassword}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                  style={{ width: "100%" }}
                  id="password_confirm_input"
                  label="Confirmer mot  de passe"
                  type="password"
                  autoComplete="current-password"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.address}
                  onChange={(e) =>
                    setUserData({ ...userData, address: e.target.value })
                  }
                  style={{ width: "100%" }}
                  id="addres_input"
                  label="Address"
                  variant="filled"
                  required
                />
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.country}
                  onChange={(e) =>
                    setUserData({ ...userData, country: e.target.value })
                  }
                  style={{ width: "100%" }}
                  id="country_input"
                  label="Country"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.region}
                  onChange={(e) =>
                    setUserData({ ...userData, region: e.target.value })
                  }
                  style={{ width: "100%" }}
                  id="region_input"
                  label="Region"
                  variant="filled"
                />
              </Col>
              <Col xs="12" md="4" lg="4">
                <TextField
                  value={userData?.city}
                  onChange={(e) =>
                    setUserData({ ...userData, city: e.target.value })
                  }
                  style={{ width: "100%" }}
                  id="City_input"
                  label="City"
                  variant="filled"
                />
              </Col>
            </Row>
            {selectedProfile && (
              <Row className={classes.rowContainer}>
                <hr />
                {selectedProfile?.previleges?.map((previlege) => {
                  return (
                    <Col xs="12" md="4" lg="4" key={previlege.id}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPrevileges((previleges) => [
                                  ...previleges,
                                  previlege.id,
                                ]);
                              } else {
                                setPrevileges((previleges) => {
                                  console.log(previleges);
                                  return previleges.filter(
                                    (previlegeId) =>
                                      previlegeId !== previlege.id
                                  );
                                });
                              }
                            }}
                          />
                        }
                        label={previlege?.description}
                      />
                    </Col>
                  );
                })}
              </Row>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            // color='primary'
            onClick={() => {
              createUser();
              toggle();
            }}
            style={{ backgroundColor: "#6DCDFF", border: "none" }}
          >
            Add
          </Button>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className={classes.optionMenu}>
        <div>
          <button className={classes.modeItems}>
            <img
              src={require("../../assets/images/listMode.png")}
              alt="listMode"
              style={{ width: "15px", height: "10px" }}
            />
          </button>
          <button className={classes.modeList}>
            <img
              src={require("../../assets/images/modeItems.png")}
              alt="listMode"
              style={{ width: "15px", height: "15px" }}
            />
          </button>
        </div>
        <button className={classes.addButton} onClick={toggle}>
          Add
        </button>
      </div>
      <div className={classes.tableContainer}>
        <table>
          <thead style={{ backgroundColor: "white", color: "#222B45" }}>
            <tr>
              <th colSpan="4">Users list</th>

              <th style={{ width: "250px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={searchUser}
                    className={classes.searchInput}
                  />
                  <img
                    src={require("../../assets/images/search.png")}
                    alt="search"
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "absolute",
                      right: "10px",
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Profile</th>
              <th>Full Name</th>
              <th>Creation date</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, index) => (
                <UserItem
                  key={index}
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>
                <button
                  className={classes.pagintionNextPrevButton}
                  onClick={() => {
                    if (pageCount - 1 > 0) {
                      setPageCount(pageCount - 1);
                    }
                  }}
                >
                  <img
                    style={{
                      height: "14px",
                      width: "16px",
                      marginRight: "0.5em",
                    }}
                    src={require("../../assets/images/prev.png")}
                    alt="previous"
                  />
                  Prev
                </button>
              </th>
              <th
                style={{
                  display: "flex",
                }}
              >
                <p>Per page:</p>
                <input
                  type="number"
                  value={perPage}
                  onChange={(e) => setPerPage(e.target.value)}
                  className={classes.paginationInput}
                />
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => {
                    if (perPage > 0) {
                      setPaginate(!paginate);
                    }
                  }}
                >
                  <img
                    style={{
                      height: "26px",
                      width: "26px",
                    }}
                    src={require("../../assets/images/check.png")}
                    alt="previous"
                  />
                </button>
              </th>
              <th style={{ padding: "0px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button className={classes.pagintionButton}>
                    {pageCount}
                  </button>
                  <button
                    className={classes.pagintionButtonNxt}
                    onClick={() => {
                      if (users.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    {pageCount + 1}
                  </button>
                </div>
              </th>

              <th colSpan="2">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "2em",
                  }}
                >
                  <button
                    className={classes.pagintionNextPrevButton}
                    onClick={() => {
                      if (users.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    <img
                      style={{
                        height: "14px",
                        width: "16px",
                        marginRight: "0.5em",
                      }}
                      src={require("../../assets/images/next.png")}
                      alt="previous"
                    />
                    Next
                  </button>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
export default Users;
