import React from "react";
import axios from "../../../utils/axios";
import classes from "./UserItem.module.css";
import styles from "../../ManagerDetails/ManagerDetails.module.css";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#00CE8A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF2D55" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const UserItem = (props) => {
  const { refresh, setRefresh } = props;
  const [modal, setModal] = React.useState(false);
  const [editUsername, setEditUsername] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editPassword, setEditPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [usernamePlaceholder, setUsernamePlaceholder] = React.useState("");
  const [emailPlaceholder, setEmailPlaceholder] = React.useState("");
  const [passwordPlaceholder, setPasswordPlaceholder] = React.useState("");
  const [firstNamePlaceholder, setFirstNamePlaceholder] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNamePlaceholder, setLastNamePlaceholder] = React.useState("");
  const [editFirstName, setEditFirstName] = React.useState(false);
  const [editLastName, setEditLastName] = React.useState(false);
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    setUsername(props.user?.username);
    setEmail(props.user?.email);
    setFirstName(props.user?.name);
    setLastName(props.user?.lastName);
    setPassword("********");
  }, [props.user]);

  const editProfile = () => {
    let formData = new FormData();
    if (file) {
      formData.append("file", file);
      axios
        .post("/upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          //   console.log(res.data);
          setRefresh(!refresh);
          props.user &&
            axios
              .put("/user/profile_img/" + props.user.id, {
                url: res.data.file_url.substring(1),
              })
              .then((res) => {
                // console.log(res.data);

                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Votre compte a été modifié avec succès",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((err) => {
                console.log({ err });
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Veuillez enter des données valide!",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Veuillez enter des données valide!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (
      (editFirstName && firstNamePlaceholder !== "") ||
      (editLastName && lastNamePlaceholder !== "")
    ) {
      axios
        .put("/user/edit_profile/" + props.user.id, {
          new_first_name:
            firstNamePlaceholder !== "" ? firstNamePlaceholder : firstName,
          new_last_name:
            lastNamePlaceholder !== "" ? lastNamePlaceholder : lastName,
        })
        .then((res) => {
          setRefresh(!refresh);
          setEditFirstName(false);
          setEditLastName(false);
          setFirstNamePlaceholder("");
          setLastNamePlaceholder("");

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Veuillez enter des données valide!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }

    if (editUsername && usernamePlaceholder !== "") {
      axios
        .put("/user/edit_profile/" + props.user.id, {
          new_username:
            usernamePlaceholder !== "" ? usernamePlaceholder : username,
        })
        .then((res) => {
          setRefresh(!refresh);
          setUsernamePlaceholder("");
          setEditUsername(false);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (editEmail && emailPlaceholder !== "") {
      axios

        .put("/user/edit_profile/" + props.user.id, {
          new_email: emailPlaceholder,
        })
        .then((res) => {
          setRefresh(!refresh);
          //   console.log(res.data);
          setEmailPlaceholder("");
          setEditEmail(false);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Veuillez enter des données valide!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (editPassword && passwordPlaceholder !== "") {
      axios
        .put("/user/edit_password/" + props.user.id, {
          new_password: passwordPlaceholder,
        })
        .then((res) => {
          setRefresh(!refresh);
          //   console.log(res.data);
          setPasswordPlaceholder("");
          setEditPassword(false);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Veuillez enter des données valide!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };
  let navigate = useNavigate();
  // const { refresh, setRefresh } = props;
  const [active, setActive] = React.useState(props.user.active);
  const activeStatusChange = (status) => {
    let temp = status.toString();
    let activeStatus = temp.charAt(0).toUpperCase() + temp.slice(1);
    axios
      .put(`/user/active`, {
        status: activeStatus,
        user_id: props.user.id,
      })
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status du compte utilisateur changé avec succès!",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <tr>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
            src={
              props.user?.profileImg
                ? process.env.REACT_APP_APIURL + props.user?.profileImg
                : require("../../../assets/images/profile.png")
            }
            alt="profile"
          />

          <div style={{ marginLeft: "1em" }}>
            <p>{props.user?.username}</p>
            <p style={{ color: "#8F9BB3" }}>{props.user?.email}</p>
          </div>
        </div>
      </td>
      <td>{props.user?.lastName + " " + props.user?.name}</td>
      <td>{props.user?.creation_date}</td>
      <td>
        <div
          style={{
            borderRadius: "15px",
            paddingBlock: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "130px",
            color: "#fff",
          }}
          className={
            props.user?.role === "manager"
              ? classes.adminRole
              : props.user?.role === "employee"
              ? classes.educateurRole
              : classes.therapeuteRole
          }
        >
          {props.user?.role}
        </div>
      </td>
      <td>
        <div>
          <Modal isOpen={modal} toggle={toggle} centered scrollable>
            <ModalHeader toggle={toggle}>Modifier le profil </ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <div className={styles.profile_pic}>
                      <label className={styles.customFileUpload1}>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />

                        <img
                          src={require("../../../assets/images/editColored.png")}
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </label>
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : props.user?.profileImg
                            ? process.env.REACT_APP_APIURL +
                              props.user?.profileImg
                            : require("../../../assets/images/profile.png")
                        }
                        id="output"
                        alt="profile img"
                        className={styles.profileImageChanger}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Nom:
                    </p>
                    <div className={styles.profileEditField}>
                      {editLastName ? (
                        <input
                          type="text"
                          placeholder="Enter your last name"
                          value={lastNamePlaceholder}
                          onChange={(e) => {
                            setLastNamePlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {lastName}
                        </p>
                      )}
                      <button
                        className={styles.editBtn}
                        onClick={() => setEditLastName(!editLastName)}
                      >
                        <img
                          src={
                            editLastName
                              ? require("../../../assets/images/clear.png")
                              : require("../../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Prénom:
                    </p>
                    <div className={styles.profileEditField}>
                      {editFirstName ? (
                        <input
                          type="text"
                          placeholder="Enter your new first name"
                          value={firstNamePlaceholder}
                          onChange={(e) => {
                            setFirstNamePlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {firstName}
                        </p>
                      )}
                      <button
                        className={styles.editBtn}
                        onClick={() => setEditFirstName(!editFirstName)}
                      >
                        <img
                          src={
                            editFirstName
                              ? require("../../../assets/images/clear.png")
                              : require("../../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Username:
                    </p>
                    <div className={styles.profileEditField}>
                      {editUsername ? (
                        <input
                          type="text"
                          placeholder="Enter your new Username"
                          value={usernamePlaceholder}
                          onChange={(e) => {
                            setUsernamePlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {username}
                        </p>
                      )}
                      <button
                        className={styles.editBtn}
                        onClick={() => setEditUsername(!editUsername)}
                      >
                        <img
                          src={
                            editUsername
                              ? require("../../../assets/images/clear.png")
                              : require("../../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Email:
                    </p>
                    <div className={styles.profileEditField}>
                      {editEmail ? (
                        <input
                          type="text"
                          placeholder="Enter your new Email"
                          value={emailPlaceholder}
                          onChange={(e) => {
                            setEmailPlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {email}
                        </p>
                      )}
                      <button
                        className={styles.editBtn}
                        onClick={() => setEditEmail(!editEmail)}
                      >
                        <img
                          src={
                            editEmail
                              ? require("../../../assets/images/clear.png")
                              : require("../../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Password:
                    </p>
                    <div className={styles.profileEditField}>
                      {editPassword ? (
                        <input
                          type="password"
                          placeholder="Enter your new Password"
                          value={passwordPlaceholder}
                          onChange={(e) => {
                            setPasswordPlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {password}
                        </p>
                      )}
                      <button
                        className={styles.editBtn}
                        onClick={() => setEditPassword(!editPassword)}
                      >
                        <img
                          src={
                            editPassword
                              ? require("../../../assets/images/clear.png")
                              : require("../../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  toggle();
                  setEditUsername(false);
                  setEditEmail(false);
                  setEditPassword(false);
                  setUsernamePlaceholder("");
                  setEmailPlaceholder("");
                  setPasswordPlaceholder("");
                  setFile(null);
                }}
                style={{
                  backgroundColor: "#DCDCDC",
                  border: "none",
                  color: "#717171",
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={() => {
                  editProfile();
                  toggle();
                }}
                style={{ backgroundColor: "#6DCDFF", border: "none" }}
              >
                Enregister
              </Button>
            </ModalFooter>
          </Modal>
          <button
            onClick={toggle}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <img
              src={require("../../../assets/images/edit-icon.png")}
              alt="details"
              style={{ width: "25px", height: "25px", marginRight: "1rem" }}
            />
          </button>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              if (props.user?.role === "manager") {
                navigate("/manager/".concat(props.user?.id));
              } else if (props.user?.role === "client") {
                navigate("/clients/".concat(props.user?.id));
              } else {
                navigate("/utilisateurs/".concat(props.user?.id));
              }
            }}
          >
            <img
              src={require("../../../assets/images/detailsLink.png")}
              alt="details"
              style={{ width: "25px", height: "25px", marginRight: "1rem" }}
            />
          </button>
          {/* <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={deleteHandler}
          >
            <img
              src={require('../../../assets/images/delete.png')}
              alt='listMode'
              style={{ width: '17px', height: '20px' }}
            />
          </button> */}
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} />}
            label=""
            checked={active}
            onChange={(e) => {
              setActive(e.target.checked);
              activeStatusChange(e.target.checked);
            }}
          />
        </div>
      </td>
    </tr>
  );
};
export default UserItem;
