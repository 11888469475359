import React from 'react';

const AlertItem = (props) => {
  return (
    <tr>
      <td>
        {props.alert.client_name} {props.alert.client_lastname}
      </td>

      <td>{props.alert.creation_date}</td>
      <td>{props.alert.danger_degree}</td>
      <td>{props.alert.client_mobile}</td>
      <td>{props.alert.status}</td>

      <td>
        <div>
          <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={() => {
              props.setDescription(props.alert.description);
              props.setAlertId(props.alert.id);
              props.toggleAlert(props.alert.id);
            }}
          >
            <img
              src={require('../../../assets/images/detailsLink.png')}
              alt='details'
              style={{ width: '25px', height: '25px', marginRight: '1rem' }}
            />
          </button>
        </div>
      </td>
    </tr>
  );
};
export default AlertItem;
