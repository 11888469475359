import React from "react";
import classes from "../ManagerDetails/ManagerDetails.module.css";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import * as moment from "moment";
import "moment/locale/fr";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import GlobalContext from "../../contexts/GlobalContext";

import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import TeamItem from "../Calendrier/TeamItem/TeamItem";
import PoolItem from "../ClientDetails/PoolItem/PoolItem";

import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import LocalStorageService from "../../utils/localStorageService";

import Crop75Icon from "@mui/icons-material/Crop75";
import PropTypes from "prop-types";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <Crop75Icon fontSize="large" color="success" />,
    label: "Low",
  },
  2: {
    icon: <Crop75Icon fontSize="large" color="success" />,
    label: "Low",
  },
  3: {
    icon: <Crop75Icon fontSize="large" color="warning" />,
    label: "Medium",
  },
  4: {
    icon: <Crop75Icon fontSize="large" color="error" />,
    label: "High",
  },
  5: {
    icon: <Crop75Icon fontSize="large" color="error" />,
    label: "High",
  },
};
function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};
const Profile = () => {
  const { user, refresh, setRefresh } = React.useContext(GlobalContext);

  let navigate = useNavigate();
  const [activities, setActivities] = React.useState(null);
  const [commingActivities, setCommingActivities] = React.useState(null);
  const [teams, setTeams] = React.useState();
  const [selectedTeam, setSelectedTeam] = React.useState("1");
  //   const [refresh, setRefresh] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [pools, setPools] = React.useState(null);
  const [pagePools, setPagePools] = React.useState();
  const [poolModal, setPoolModal] = React.useState(false);
  const [poolData, setPoolData] = React.useState({
    name: "",
    address: "",
    city: "",
    country: "",
    region: "",
  });
  const [modal, setModal] = React.useState(false);
  const [editUsername, setEditUsername] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const [editPassword, setEditPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [usernamePlaceholder, setUsernamePlaceholder] = React.useState("");
  const [emailPlaceholder, setEmailPlaceholder] = React.useState("");
  const [passwordPlaceholder, setPasswordPlaceholder] = React.useState("");
  const [firstNamePlaceholder, setFirstNamePlaceholder] = React.useState("");
  const [lastNamePlaceholder, setLastNamePlaceholder] = React.useState("");
  const [editFirstName, setEditFirstName] = React.useState(false);
  const [editLastName, setEditLastName] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [modalAlert, setModalAlert] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [priority, setPriority] = React.useState("");
  const [emergencyPoolId, setEmergencyPoolId] = React.useState("");

  React.useEffect(() => {
    let token = LocalStorageService.getAccessToken();
    if (!token) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editProfile = () => {
    let formData = new FormData();
    if (file) {
      formData.append("file", file);
      axios
        .post("/upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          //   console.log(res.data);
          user &&
            axios
              .put("/user/profile_img", {
                url: res.data.file_url.substring(1),
              })
              .then((res) => {
                // console.log(res.data);
                setRefresh(!refresh);
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Votre compte a été modifié avec succès",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((err) => {
                console.log({ err });
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Veuillez enter des données valide!",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Veuillez enter des données valide!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (
      (editFirstName && firstNamePlaceholder !== "") ||
      (editLastName && lastNamePlaceholder !== "")
    ) {
      axios
        .put("/user/edit_profile/" + user.id, {
          new_first_name:
            firstNamePlaceholder !== "" ? firstNamePlaceholder : user.name,
          new_last_name:
            lastNamePlaceholder !== "" ? lastNamePlaceholder : user.lastName,
        })
        .then((res) => {
          setRefresh(!refresh);
          setEditFirstName(false);
          setEditLastName(false);
          setFirstNamePlaceholder("");
          setLastNamePlaceholder("");

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (editUsername && usernamePlaceholder !== "") {
      axios
        .put("/user/edit_profile", {
          new_username: usernamePlaceholder,
        })
        .then((res) => {
          //   console.log(res.data);
          setUsernamePlaceholder("");
          setEditUsername(false);
          setRefresh(!refresh);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (editEmail && emailPlaceholder !== "") {
      axios

        .put("/user/edit_profile", {
          new_email: emailPlaceholder,
        })
        .then((res) => {
          //   console.log(res.data);
          setEmailPlaceholder("");
          setEditEmail(false);
          setRefresh(!refresh);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
    if (editPassword && passwordPlaceholder !== "") {
      axios
        .put("/user/edit_password", {
          new_password: passwordPlaceholder,
        })
        .then((res) => {
          //   console.log(res.data);
          setPasswordPlaceholder("");
          setEditPassword(false);
          setRefresh(!refresh);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Votre compte a été modifié avec succès",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };
  const toggle = () => {
    setModal(!modal);
  };
  const toggleAlert = () => {
    setModalAlert(!modalAlert);
  };
  const togglePoolModal = () => {
    setPoolModal(!poolModal);
  };
  const searchPool = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredPools = user.pools.filter((pool) => {
        return pool.name.toLowerCase().includes(search.toLowerCase());
      });
      setPools(filteredPools);
    } else {
      setPools(pagePools);
    }
  };
  const createPool = () => {
    if (
      poolData.name !== "" &&
      poolData.address !== "" &&
      poolData.city !== "" &&
      poolData.country !== "" &&
      poolData.region !== "" &&
      user?.id
    ) {
      axios
        .post(`/pool/create`, {
          name: poolData.name,
          address: poolData.address,
          city: poolData.city,
          country: poolData.country,
          region: poolData.region,
          client_id: user?.id,
        })
        .then((res) => {
          // console.log(res.data);
          setRefresh(!refresh);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Pool added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setPoolModal(!poolModal);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "un probleme s'est produit lors de la création d'un pool",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all required fields",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };
  React.useEffect(() => {
    axios
      .get(`/team/`)
      .then((res) => {
        setTeams(res.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);
  React.useEffect(() => {
    setUsername(user?.username);
    setEmail(user?.email);
    setPassword("********");
    user &&
      axios
        .get("/pool/?client_id=" + user?.id + "&page=" + pageCount)
        .then((res) => {
          setPools(res.data.pools);
          setPagePools(res.data.pools);
        })
        .catch((err) => {
          console.log(err);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, refresh, user]);
  React.useEffect(() => {
    const today = new Date();
    if (user?.role === "manager") {
      console.log(user?.role);
      selectedTeam &&
        axios
          .get(
            "/activity/?fromdate=" +
              today.getFullYear() +
              "-01-01&team_id=" +
              selectedTeam
          )
          .then((res) => {
            let temp2 = res.data.activities;
            temp2.sort(function (b, a) {
              a = new Date(
                moment(a?.date.concat(" ", a.time)) + 1 * 60 * 60 * 1000
              );
              b = new Date(
                moment(b.date.concat(" ", b.time)) + 1 * 60 * 60 * 1000
              );
              return a > b ? -1 : a < b ? 1 : 0;
            });
            var historique = [];
            var future = [];
            temp2.forEach((activity) => {
              if (activity.status === "done") {
                historique.push(activity);
              } else if (
                activity.status === "waiting" ||
                activity.status === "accepted"
              ) {
                future.push(activity);
              }
            });
            setCommingActivities(future);
            setActivities(historique);
            // console.log(temp2);
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      axios
        .get(
          "/activity/?fromdate=" +
            today.getFullYear() +
            "-01-01&team_id=" +
            user?.team_id
        )
        .then((res) => {
          let temp2 = res.data.activities;
          temp2.sort(function (b, a) {
            a = new Date(
              moment(a?.date.concat(" ", a.time)) + 1 * 60 * 60 * 1000
            );
            b = new Date(
              moment(b.date.concat(" ", b.time)) + 1 * 60 * 60 * 1000
            );
            return a > b ? -1 : a < b ? 1 : 0;
          });
          var historique = [];
          var future = [];
          temp2.forEach((activity) => {
            if (activity.status === "done") {
              historique.push(activity);
            } else if (
              activity.status === "waiting" ||
              activity.status === "accepted"
            ) {
              future.push(activity);
            }
          });
          setCommingActivities(future);
          setActivities(historique);
          // console.log(temp2);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedTeam, refresh, user]);

  const createAlert = () => {
    if (description === "" || priority === "" || emergencyPoolId === "") {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all fields",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios
        .post("/alert/create", {
          description: description,
          danger_degree: priority,
          pool_id: emergencyPoolId,
        })
        .then((res) => {
          // console.log(res.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Emergency alert sent successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          const alert_id = res.data.alert.id;
          console.log(alert_id);
          const formData = new FormData();
          if (file) {
            formData.append("file", file);
            axios
              .post("/upload/", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                // console.log(res.data);
                // console.log(alert_id);
                user &&
                  file &&
                  axios
                    .post("/docs/add", {
                      user_id: user.id,
                      alert_id: alert_id,
                      url: res.data.file_url,
                      fileName: file.name,
                    })
                    .then((res) => {
                      // console.log(res.data);

                      setRefresh(!refresh);
                    })
                    .catch((err) => {
                      console.log({ err });
                    });
              })
              .catch((err) => {
                console.log({ err });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className={classes.mainContainer}>
      {/* <div className={classes.optionMenu}>
        <button className={classes.saveButton}>Enregister</button>
      </div> */}
      <Modal
        isOpen={modalAlert}
        toggle={toggleAlert}
        size="md"
        centered
        scrollable
      >
        <ModalHeader toggle={toggleAlert}>Create Emergency Alert</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <p className={classes.inputLabel}>Description: </p>
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="description_input"
                  variant="filled"
                  label="Emergency description"
                  multiline
                  maxRows={4}
                  minRows={4}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Col>
              <Col xs="12" md="12" lg="12">
                <p className={classes.inputLabel}>Priority:</p>
                <StyledRating
                  name="highlight-selected-only"
                  defaultValue={1}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  onChange={(e, value) => {
                    setPriority(customIcons[value].label);
                  }}
                  highlightSelectedOnly
                />
              </Col>
              <Col xs="12" md="12" lg="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <label className={classes.customFileUpload}>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                    Ajouter Votre Document
                    <img
                      src={require("../../assets/images/upload.png")}
                      alt="upload icon"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "1rem",
                      }}
                    />
                  </label>
                  {file && <p className={classes.nameFile}>{file.name}</p>}
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              createAlert();
              toggleAlert();
            }}
            style={{ backgroundColor: "#6DCDFF", border: "none" }}
          >
            Create
          </Button>
          <Button
            onClick={toggleAlert}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {user && (
        <Container fluid className={classes.activityDetails}>
          <Modal isOpen={modal} toggle={toggle} centered scrollable>
            <ModalHeader toggle={toggle}>Modifier Votre Profil </ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <div className={classes.profile_pic}>
                      <label className={classes.customFileUpload1}>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />

                        <img
                          src={require("../../assets/images/editColored.png")}
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </label>
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : user?.profileImg
                            ? process.env.REACT_APP_APIURL + user?.profileImg
                            : require("../../assets/images/profile.png")
                        }
                        id="output"
                        alt="profile img"
                        className={classes.profileImageChanger}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Nom:
                    </p>
                    <div className={classes.profileEditField}>
                      {editLastName ? (
                        <input
                          type="text"
                          placeholder="Enter your last name"
                          value={lastNamePlaceholder}
                          onChange={(e) => {
                            setLastNamePlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {user.lastName}
                        </p>
                      )}
                      <button
                        className={classes.editBtn}
                        onClick={() => setEditLastName(!editLastName)}
                      >
                        <img
                          src={
                            editLastName
                              ? require("../../assets/images/clear.png")
                              : require("../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Prénom:
                    </p>
                    <div className={classes.profileEditField}>
                      {editFirstName ? (
                        <input
                          type="text"
                          placeholder="Enter your new first name"
                          value={firstNamePlaceholder}
                          onChange={(e) => {
                            setFirstNamePlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {user.name}
                        </p>
                      )}
                      <button
                        className={classes.editBtn}
                        onClick={() => setEditFirstName(!editFirstName)}
                      >
                        <img
                          src={
                            editFirstName
                              ? require("../../assets/images/clear.png")
                              : require("../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Username:
                    </p>
                    <div className={classes.profileEditField}>
                      {editUsername ? (
                        <input
                          type="text"
                          placeholder="Enter your new Username"
                          value={usernamePlaceholder}
                          onChange={(e) => {
                            setUsernamePlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {username}
                        </p>
                      )}
                      <button
                        className={classes.editBtn}
                        onClick={() => setEditUsername(!editUsername)}
                      >
                        <img
                          src={
                            editUsername
                              ? require("../../assets/images/clear.png")
                              : require("../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Email:
                    </p>
                    <div className={classes.profileEditField}>
                      {editEmail ? (
                        <input
                          type="text"
                          placeholder="Enter your new Email"
                          value={emailPlaceholder}
                          onChange={(e) => {
                            setEmailPlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {email}
                        </p>
                      )}
                      <button
                        className={classes.editBtn}
                        onClick={() => setEditEmail(!editEmail)}
                      >
                        <img
                          src={
                            editEmail
                              ? require("../../assets/images/clear.png")
                              : require("../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        color: "#8f9bb3",
                        fontWeight: "500",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Password:
                    </p>
                    <div className={classes.profileEditField}>
                      {editPassword ? (
                        <input
                          type="password"
                          placeholder="Enter your new Password"
                          value={passwordPlaceholder}
                          onChange={(e) => {
                            setPasswordPlaceholder(e.target.value);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        />
                      ) : (
                        <p
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            width: "80%",
                          }}
                        >
                          {password}
                        </p>
                      )}
                      <button
                        className={classes.editBtn}
                        onClick={() => setEditPassword(!editPassword)}
                      >
                        <img
                          src={
                            editPassword
                              ? require("../../assets/images/clear.png")
                              : require("../../assets/images/editColored.png")
                          }
                          alt="listMode"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  toggle();
                  setEditUsername(false);
                  setEditEmail(false);
                  setEditPassword(false);
                  setUsernamePlaceholder("");
                  setEmailPlaceholder("");
                  setPasswordPlaceholder("");
                  setFile(null);
                }}
                style={{
                  backgroundColor: "#DCDCDC",
                  border: "none",
                  color: "#717171",
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={() => {
                  editProfile();
                  toggle();
                }}
                style={{ backgroundColor: "#6DCDFF", border: "none" }}
              >
                Enregister
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={poolModal}
            toggle={togglePoolModal}
            size="lg"
            centered
            scrollable
          >
            <ModalHeader toggle={togglePoolModal}>Add Pool</ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="name_pool_input"
                      variant="filled"
                      label="Pool name"
                      value={poolData.name}
                      onChange={(e) =>
                        setPoolData({ ...poolData, name: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_address_input"
                      variant="filled"
                      label="Address"
                      value={poolData.address}
                      onChange={(e) =>
                        setPoolData({ ...poolData, address: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_country_input"
                      variant="filled"
                      label="Country"
                      value={poolData.country}
                      onChange={(e) =>
                        setPoolData({ ...poolData, country: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_city_input"
                      variant="filled"
                      value={poolData.city}
                      label="City"
                      onChange={(e) =>
                        setPoolData({ ...poolData, city: e.target.value })
                      }
                    />
                  </Col>
                </Row>

                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_region_input"
                      variant="filled"
                      label="Region"
                      value={poolData.region}
                      onChange={(e) =>
                        setPoolData({ ...poolData, region: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  createPool();
                }}
                style={{
                  backgroundColor: "#6DCDFF",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                Add
              </Button>
              <Button
                onClick={togglePoolModal}
                style={{
                  backgroundColor: "#DCDCDC",
                  border: "none",
                  color: "#717171",
                  borderRadius: "10px",
                }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </Modal>
          <Row className={classes.activityDetailsTitle}>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginRight: "0.5rem",
                }}
                src={
                  user.profileImg
                    ? process.env.REACT_APP_APIURL + user.profileImg
                    : require("../../assets/images/profile.png")
                }
                alt="profile"
              />
              <h5 style={{ marginBottom: "0px" }}>
                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}{" "}
                {user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}
              </h5>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Type d'accès:{" "}
                <span className={classes.detailsContent}>{user.role}</span>
              </p>
            </Col>
            <Col>
              <p className={classes.detailsTitle}>
                E-mail :{" "}
                <span className={classes.detailsContent}>{user.email}</span>
              </p>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Date d'inscription:{" "}
                <span className={classes.detailsContent}>
                  {user.creation_date ? user.creation_date : "22-Mar-2022"}
                </span>
              </p>
            </Col>
            {user.previleges.find((prev) => prev.name === "GPROFIL") && (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button className={classes.editButtons} onClick={toggle}>
                  <img
                    src={require("../../assets/images/editColored.png")}
                    alt="listMode"
                    style={{ width: "25px", height: "25px" }}
                  />
                </button>
              </Col>
            )}
          </Row>

          {user?.role === "manager" && (
            <Row className={classes.rowHistoryContainer}>
              <Col
                lg="3"
                //   style={{
                //     height: '100%',
                //     display: 'flex',
                //     flexDirection: 'column',
                //     justifyContent: 'space-between',
                //     marginRight: '2rem',
                //     marginLeft: '2rem',
                //   }}
              >
                <Row className={classes.ActivityList}>
                  <div className={classes.sectionTitle}>
                    <p>List of teams</p>
                  </div>
                  <div className={classes.todaysActScrollable}>
                    <FormControl style={{ width: "100%" }}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedTeam}
                        onChange={handleChange}
                      >
                        {teams &&
                          teams.map((team) => {
                            return (
                              <div key={team.id} className={classes.teamCard}>
                                <TeamItem
                                  selectedTeam={selectedTeam}
                                  setSelectedTeam={setSelectedTeam}
                                  refresh={refresh}
                                  setRefresh={setRefresh}
                                  team={team}
                                  handleChange={handleChange}
                                />
                              </div>
                            );
                          })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Row>
              </Col>
              <Col lg="4" className={classes.colHistory}>
                <Row className={classes.sectionContainerTitle}>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ margin: "0px" }}>Events history</h6>
                  </Col>
                </Row>
                <Row className={classes.historiqueContainer}>
                  <Col>
                    <Timeline>
                      {activities &&
                        activities.map((activity, index) => {
                          if (index !== activities.length - 1) {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {activities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>

                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity?.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          } else {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {activities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>

                                  <p style={{ color: "#8F9BB3" }}>
                                    {activity.activity_desc}
                                  </p>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          }
                        })}
                    </Timeline>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" className={classes.colHistory}>
                <Row className={classes.sectionContainerTitle}>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ margin: "0px" }}>Upcoming events</h6>
                  </Col>
                </Row>
                <Row className={classes.historiqueContainer}>
                  <Col>
                    <Timeline>
                      {commingActivities &&
                        commingActivities.map((activity, index) => {
                          if (index !== commingActivities.length - 1) {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {commingActivities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>

                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          } else {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {commingActivities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          }
                        })}
                    </Timeline>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {user?.role === "client" && (
            <div className={classes.tableContainer}>
              <table>
                <thead style={{ backgroundColor: "white", color: "#222B45" }}>
                  <tr>
                    <th colSpan="4">Pools list</th>

                    <th style={{ width: "250px" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "90%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={searchPool}
                          className={classes.searchInput}
                        />
                        <img
                          src={require("../../assets/images/search.png")}
                          alt="search"
                          style={{
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            right: "10px",
                          }}
                        />
                      </div>
                    </th>
                    <th style={{ width: "200px" }}>
                      <button
                        className={classes.docButtons}
                        onClick={togglePoolModal}
                      >
                        <img
                          src={require("../../assets/images/addWhite.png")}
                          alt="listMode"
                          style={{ width: "35px", height: "35px" }}
                        />
                        Add Pool
                      </button>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Pool Name</th>
                    <th>Address</th>
                    <th>Last intervention date</th>
                    <th>Sheet</th>
                    <th>Details</th>
                    <th>Add alert</th>
                  </tr>
                </thead>
                <tbody>
                  {pools &&
                    pools.map((pool, index) => (
                      <PoolItem
                        key={index}
                        pool={pool}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        toggleAlert={toggleAlert}
                        setEmergencyPoolId={setEmergencyPoolId}
                      />
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>
                      <button
                        className={classes.pagintionNextPrevButton}
                        onClick={() => {
                          if (pageCount - 1 > 0) {
                            setPageCount(pageCount - 1);
                          }
                        }}
                      >
                        <img
                          style={{
                            height: "14px",
                            width: "16px",
                            marginRight: "0.5em",
                          }}
                          src={require("../../assets/images/prev.png")}
                          alt="previous"
                        />
                        Prev
                      </button>
                    </th>
                    <th colSpan="4" style={{ padding: "0px" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button className={classes.pagintionButton}>
                          {pageCount}
                        </button>
                        <button
                          className={classes.pagintionButtonNxt}
                          onClick={() => {
                            if (pools.length > 0) {
                              setPageCount(pageCount + 1);
                            }
                          }}
                        >
                          {pageCount + 1}
                        </button>
                      </div>
                    </th>

                    <th>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingRight: "2em",
                        }}
                      >
                        <button
                          className={classes.pagintionNextPrevButton}
                          onClick={() => {
                            if (pools.length > 0) {
                              setPageCount(pageCount + 1);
                            }
                          }}
                        >
                          <img
                            style={{
                              height: "14px",
                              width: "16px",
                              marginRight: "0.5em",
                            }}
                            src={require("../../assets/images/next.png")}
                            alt="previous"
                          />
                          Next
                        </button>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
          {user?.role !== "client" && user?.role !== "manager" && (
            <Row className={classes.rowHistoryContainer}>
              <Col xs="5" className={classes.colHistory}>
                <Row className={classes.sectionContainerTitle}>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ margin: "0px" }}>Events history</h6>
                  </Col>
                </Row>
                <Row className={classes.historiqueContainer}>
                  <Col>
                    <Timeline>
                      {activities &&
                        activities.map((activity, index) => {
                          if (index !== activities.length - 1) {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {activities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>

                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          } else {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {activities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>

                                  <p style={{ color: "#8F9BB3" }}>
                                    {activity.activity_desc}
                                  </p>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          }
                        })}
                    </Timeline>
                  </Col>
                </Row>
              </Col>
              <Col xs="6" className={classes.colHistory}>
                <Row className={classes.sectionContainerTitle}>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ margin: "0px" }}>Upcoming events</h6>
                  </Col>
                </Row>
                <Row className={classes.historiqueContainer}>
                  <Col>
                    <Timeline>
                      {commingActivities &&
                        commingActivities.map((activity, index) => {
                          if (index !== commingActivities.length - 1) {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {commingActivities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>

                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          } else {
                            return (
                              <TimelineItem key={activity.id}>
                                <TimelineOppositeContent
                                  style={{ flex: 0.5 }}
                                  color="text.secondary"
                                >
                                  {commingActivities[index - 1]?.date !==
                                    activity.date && (
                                    <h5>
                                      {moment(
                                        new Date(
                                          moment(
                                            activity.date.concat(
                                              " ",
                                              activity.time
                                            )
                                          ) +
                                            1 * 60 * 60 * 1000
                                        )
                                      ).format("DD MMMM YYYY")}
                                    </h5>
                                  )}
                                </TimelineOppositeContent>

                                <TimelineSeparator>
                                  <TimelineDot />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <h5> {activity.time}</h5>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{activity.name}</p>
                                    {activity?.intervention_id && (
                                      <button
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            "/interventions/".concat(
                                              activity.intervention_id
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/images/detailsLink.png")}
                                          alt="details"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          }
                        })}
                    </Timeline>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </div>
  );
};

export default Profile;
