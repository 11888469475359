import React from "react";
import GlobalContext from "../../contexts/GlobalContext";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import TextField from "@material-ui/core/TextField";
import classes from "./Calendrier.module.css";
import axios from "../../utils/axios";
import moment from "moment";
import CalendarComponent from "./CalendarComponent/CalendarComponent";
import TeamItem from "./TeamItem/TeamItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import AddActivityModal from "./../../components/AddActivityModal/AddActivityModal";
const Calendrier = () => {
  const { user, setActivities, today } = React.useContext(GlobalContext);
  const [selectedTeam, setSelectedTeam] = React.useState(
    user?.role !== "manager" ? user?.team_id : 1
  );
  const items = [];
  const [teams, setTeams] = React.useState();
  const [refresh, setRefresh] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalAdd, setModalAdd] = React.useState(false);
  const [teamData, setTeamData] = React.useState({
    name: "",
  });
  const addTeam = () => {
    if (teamData.name) {
      axios
        .post(`/team/create`, {
          name: teamData.name,
        })
        .then((res) => {
          console.log(res.data);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
    setRefresh(!refresh);
  };
  const toggle = () => {
    setModal(!modal);
  };
  const toggleAdd = () => {
    setModalAdd(!modalAdd);
  };
  React.useEffect(() => {
    if (user?.role !== "manager") {
      setSelectedTeam(user?.team_id);
    }
  }, [user]);
  React.useEffect(() => {
    axios
      .get(`/team/`)
      .then((res) => {
        setTeams(res.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);
  React.useEffect(() => {
    if (user?.role !== "manager") {
      setSelectedTeam(user?.team_id);
    }
    if (selectedTeam) {
      axios
        .get(
          "/activity/?fromdate=" +
            today.getFullYear() +
            "-01-01&team_id=" +
            selectedTeam
        )
        .then((res) => {
          // console.log(res.data);
          res.data.activities.map((activity) => {
            var couleur = "#FF2D55";
            if (activity.status === "waiting") {
              couleur = "#FF9D2B";
            }
            if (activity.status === "accepted") {
              couleur = "#00E096";
            }
            if (activity.status === "done") {
              couleur = "#0095FF";
            }
            // let keyDate = moment(new Date(activity.date)).format('DD-MM-YYYY');
            // var key = keyDate;
            let correctTime = moment(
              new Date(moment(activity?.date.concat(" ", activity?.time)))
            ).format("HH:mm");

            items.push({
              id: activity.id,
              activity: activity,
              startAt: moment(
                activity.date + " " + correctTime,
                "YYYY-MM-DD HH:mm"
              )
                .toISOString()
                .toString(),
              endAt: moment(
                activity.date +
                  " " +
                  moment(
                    new Date(
                      moment(activity?.date.concat(" ", correctTime)) +
                        activity.duration * 60 * 1000
                    )
                  ).format("HH:mm"),
                "YYYY-MM-DD HH:mm"
              )
                .toISOString()
                .toString(),
              summary: activity.name + " (" + activity.client_name + ")",
              color: couleur,
            });
            //   items[key] = temp;
            // } else {
            //   items[key] = [
            //     {
            //       id: activity.id,
            //       activity: activity,
            //       startAt: activity.date + 'T' + correctTime + ':00.000Z',
            //       endAt:
            //         activity.date +
            //         'T' +
            //         moment(
            //           new Date(
            //             moment(activity?.date.concat(' ', correctTime)) +
            //               2 * 60 * 60 * 1000
            //           )
            //         ).format('HH:mm') +
            //         ':00.000Z',
            //       summary: activity.name,
            //       color: couleur,
            //     },
            //   ];
            // }
            return items;
          });
          setActivities(items);
          // setActivities(res.data.activities);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, refresh]);
  return (
    <div className={classes.mainContainer}>
      <Modal isOpen={modal} toggle={toggle} size="lg" centered scrollable>
        <ModalHeader toggle={toggle}>Add Team</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col xs="12" md="12" lg="12">
                <p className={classes.inputLabel}>Name:</p>
                <TextField
                  value={teamData?.name}
                  onChange={(e) =>
                    setTeamData({ ...teamData, name: e.target.value })
                  }
                  required
                  style={{ width: "100%" }}
                  id="nom_input"
                  variant="outlined"
                  size="small"
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              addTeam();
              toggle();
            }}
            style={{ backgroundColor: "#6DCDFF", border: "none" }}
          >
            Ajouter
          </Button>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
      <AddActivityModal
        modal={modalAdd}
        toggle={toggleAdd}
        user={user}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedTeam={selectedTeam}
      />
      {user?.previleges.find((v) => v.name === "ADDEVENT") !== undefined && (
        <button
          onClick={toggleAdd}
          style={{
            position: "absolute",
            bottom: "1.5rem",
            right: "5.5rem",
            zIndex: "10",
            width: "4rem",
            height: "4rem",
            backgroundColor: "#2096bb",
            borderRadius: "50%",
            border: "none",
          }}
        >
          <img
            src={require("../../assets/images/add.png")}
            alt="add icon"
            style={{ width: "30px", height: "30px" }}
          />
        </button>
      )}
      <Row style={{ height: "100%" }}>
        {user?.role === "manager" && (
          <Col
            lg="3"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginRight: "2rem",
              marginLeft: "2rem",
            }}
          >
            <Row className={classes.ActivityList}>
              <div className={classes.sectionTitle}>
                <p>List of teams</p>
                <button onClick={toggle} className={classes.addButton}>
                  <img
                    src={require("../../assets/images/add.png")}
                    alt="add icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
              <div className={classes.todaysActScrollable}>
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedTeam}
                    onChange={handleChange}
                  >
                    {teams &&
                      teams.map((team) => {
                        return (
                          <div key={team.id} className={classes.teamCard}>
                            <TeamItem
                              selectedTeam={selectedTeam}
                              setSelectedTeam={setSelectedTeam}
                              refresh={refresh}
                              setRefresh={setRefresh}
                              team={team}
                              handleChange={handleChange}
                            />
                          </div>
                        );
                      })}
                  </RadioGroup>
                </FormControl>
              </div>
            </Row>
          </Col>
        )}

        <CalendarComponent refresh={refresh} setRefresh={setRefresh} />
      </Row>
    </div>
  );
};
export default Calendrier;
