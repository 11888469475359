import React, { useEffect } from "react";
import GlobalContext from "./GlobalContext";
import axios from "../utils/axios";
import LocalStorageService from "../utils/localStorageService";
import moment from "moment";

const AppContext = (props) => {
  const items = [];

  const [refresh, setRefresh] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const [activities, setActivities] = React.useState(null);
  const today = new Date();
  // const date =
  //   today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  React.useEffect(() => {
    if (LocalStorageService.getAccessToken()) {
      axios
        .get("/auth/me")
        .then((res) => {
          // console.log(res.data);
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [refresh]);

  React.useEffect(() => {
    let token = LocalStorageService.getAccessToken();
    let team = user?.role !== "manager" ? user?.team_id : 1;

    // user && user?.team_id ? (team = user?.team_id) : (team = "1");
    if (token !== null) {
      user !== null &&
        axios
          .get(
            "/activity/?fromdate=" +
              today.getFullYear() +
              "-01-01&team_id=" +
              team
          )
          .then((res) => {
            res.data.activities.map((activity) => {
              var couleur = "#FF2D55";
              if (activity.status === "waiting") {
                couleur = "#FF9D2B";
              }
              if (activity.status === "accepted") {
                couleur = "#00E096";
              }
              if (activity.status === "done") {
                couleur = "#0095FF";
              }
              // let keyDate = moment(new Date(activity.date)).format(
              //   'DD-MM-YYYY'
              // );
              // var key = keyDate;

              let correctTime = moment(
                new Date(moment(activity?.date.concat(" ", activity?.time)))
              ).format("HH:mm");

              // if (items[key] !== undefined) {
              // let temp = items[key];

              items.push({
                id: activity.id,
                activity: activity,
                startAt: moment(
                  activity.date + " " + correctTime,
                  "YYYY-MM-DD HH:mm"
                )
                  .toISOString()
                  .toString(),
                endAt: moment(
                  activity.date +
                    " " +
                    moment(
                      new Date(
                        moment(activity?.date.concat(" ", correctTime)) +
                          activity.duration * 60 * 1000
                      )
                    ).format("HH:mm"),
                  "YYYY-MM-DD HH:mm"
                )
                  .toISOString()
                  .toString(),
                summary: activity.name + " (" + activity.client_name + ")",
                color: couleur,
              });
              // items[key] = temp;
              // } else {
              //   items[key] = [
              //     {
              //       id: activity.id,
              //       activity: activity,
              //       startAt: activity.date + 'T' + correctTime + ':00.000Z',
              //       endAt:
              //         activity.date +
              //         'T' +
              //         moment(
              //           new Date(
              //             moment(activity?.date.concat(' ', correctTime)) +
              //               2 * 60 * 60 * 1000
              //           )
              //         ).format('HH:mm') +
              //         ':00.000Z',
              //       summary: activity.name,
              //       color: couleur,
              //     },
              //   ];
              // }
              return items;
            });
            setActivities(items);
          })
          .catch((err) => {
            console.log(err);
          });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refresh]);

  const logoutHandler = () => {
    LocalStorageService.clearToken();
  };

  useEffect(() => {}, [user]);
  return (
    <GlobalContext.Provider
      value={{
        user,
        refresh,
        setRefresh,
        activities,
        setActivities,
        today,
        logoutHandler,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
