import React from 'react';
import classes from './ManagerDetails.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import * as moment from 'moment';
import 'moment/locale/fr';
import { Row, Col, Container } from 'reactstrap';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import TeamItem from '../Calendrier/TeamItem/TeamItem';

const ManagerDetails = () => {
  let { userId } = useParams();
  let navigate = useNavigate();
  const [user, setUser] = React.useState(null);
  const [activities, setActivities] = React.useState(null);
  const [commingActivities, setCommingActivities] = React.useState(null);
  const [teams, setTeams] = React.useState();
  const [selectedTeam, setSelectedTeam] = React.useState('1');
  const [refresh, setRefresh] = React.useState(false);

  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };
  React.useEffect(() => {
    axios
      .get(`/team/`)
      .then((res) => {
        setTeams(res.data.teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);
  React.useEffect(() => {
    axios
      .get('/auth/users?id=' + userId)
      .then((res) => {
        setUser(res.data.user);
        // console.log(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    const today = new Date();
    selectedTeam &&
      axios
        .get(
          '/activity/?fromdate=' +
            today.getFullYear() +
            '-01-01&team_id=' +
            selectedTeam
        )
        .then((res) => {
          let temp2 = res.data.activities;
          temp2.sort(function (b, a) {
            a = new Date(
              moment(a?.date.concat(' ', a.time)) + 1 * 60 * 60 * 1000
            );
            b = new Date(
              moment(b.date.concat(' ', b.time)) + 1 * 60 * 60 * 1000
            );
            return a > b ? -1 : a < b ? 1 : 0;
          });
          var historique = [];
          var future = [];
          temp2.forEach((activity) => {
            if (activity.status === 'done') {
              historique.push(activity);
            } else if (
              activity.status === 'waiting' ||
              activity.status === 'accepted'
            ) {
              future.push(activity);
            }
          });
          setCommingActivities(future);
          setActivities(historique);
          // console.log(temp2);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [selectedTeam, refresh]);
  return (
    <div className={classes.mainContainer}>
      {/* <div className={classes.optionMenu}>
        <button className={classes.saveButton}>Enregister</button>
      </div> */}
      {user && (
        <Container fluid className={classes.activityDetails}>
          <Row className={classes.activityDetailsTitle}>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  marginRight: '0.5rem',
                }}
                src={
                  user.profileImg
                    ? process.env.REACT_APP_APIURL + user.profileImg
                    : require('../../assets/images/profile.png')
                }
                alt='profile'
              />
              <h5 style={{ marginBottom: '0px' }}>
                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}{' '}
                {user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}
              </h5>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Type d'accès:{' '}
                <span className={classes.detailsContent}>{user.role}</span>
              </p>
            </Col>
            <Col>
              <p className={classes.detailsTitle}>
                E-mail :{' '}
                <span className={classes.detailsContent}>{user.email}</span>
              </p>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Date d'inscription:{' '}
                <span className={classes.detailsContent}>
                  {user.creation_date ? user.creation_date : '22-Mar-2022'}
                </span>
              </p>
            </Col>
          </Row>

          <Row className={classes.rowHistoryContainer}>
            <Col
              lg='3'
              //   style={{
              //     height: '100%',
              //     display: 'flex',
              //     flexDirection: 'column',
              //     justifyContent: 'space-between',
              //     marginRight: '2rem',
              //     marginLeft: '2rem',
              //   }}
            >
              <Row className={classes.ActivityList}>
                <div className={classes.sectionTitle}>
                  <p>List of teams</p>
                </div>
                <div className={classes.todaysActScrollable}>
                  <FormControl style={{ width: '100%' }}>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={selectedTeam}
                      onChange={handleChange}
                    >
                      {teams &&
                        teams.map((team) => {
                          return (
                            <div key={team.id} className={classes.teamCard}>
                              <TeamItem
                                selectedTeam={selectedTeam}
                                setSelectedTeam={setSelectedTeam}
                                refresh={refresh}
                                setRefresh={setRefresh}
                                team={team}
                                handleChange={handleChange}
                              />
                            </div>
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                </div>
              </Row>
            </Col>
            <Col lg='4' className={classes.colHistory}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <h6 style={{ margin: '0px' }}>Events history</h6>
                </Col>
              </Row>
              <Row className={classes.historiqueContainer}>
                <Col>
                  <Timeline>
                    {activities &&
                      activities.map((activity, index) => {
                        if (index !== activities.length - 1) {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color='text.secondary'
                              >
                                {activities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            ' ',
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format('DD MMMM YYYY')}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>

                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onClick={() => {
                                        navigate(
                                          '/interventions/'.concat(
                                            activity?.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/images/detailsLink.png')}
                                        alt='details'
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        } else {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color='text.secondary'
                              >
                                {activities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            ' ',
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format('DD MMMM YYYY')}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onClick={() => {
                                        navigate(
                                          '/interventions/'.concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/images/detailsLink.png')}
                                        alt='details'
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>

                                <p style={{ color: '#8F9BB3' }}>
                                  {activity.activity_desc}
                                </p>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      })}
                  </Timeline>
                </Col>
              </Row>
            </Col>
            <Col lg='4' className={classes.colHistory}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <h6 style={{ margin: '0px' }}>Upcoming events</h6>
                </Col>
              </Row>
              <Row className={classes.historiqueContainer}>
                <Col>
                  <Timeline>
                    {commingActivities &&
                      commingActivities.map((activity, index) => {
                        if (index !== commingActivities.length - 1) {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color='text.secondary'
                              >
                                {commingActivities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            ' ',
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format('DD MMMM YYYY')}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>

                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onClick={() => {
                                        navigate(
                                          '/interventions/'.concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/images/detailsLink.png')}
                                        alt='details'
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        } else {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color='text.secondary'
                              >
                                {commingActivities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            ' ',
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format('DD MMMM YYYY')}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  {activity?.intervention_id && (
                                    <button
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onClick={() => {
                                        navigate(
                                          '/interventions/'.concat(
                                            activity.intervention_id
                                          )
                                        );
                                      }}
                                    >
                                      <img
                                        src={require('../../assets/images/detailsLink.png')}
                                        alt='details'
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      })}
                  </Timeline>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default ManagerDetails;
