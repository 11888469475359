import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import { Routes, Route } from 'react-router-dom';
import Topbar from './Topbar';
// import Home from './../../pages/Home/Home';
import Users from './../../pages/Users/Users';
import UserDetails from './../../pages/UserDetails/UserDetails';
import Calendrier from './../../pages/Calendrier/Calendrier';
import Clients from './../../pages/Clients/Clients';
import Intervention from './../../pages/Intervention/Intervention';
import Teams from './../../pages/Teams/Teams';
import DetailsTeam from './../../pages/DetailsTeam/DetailsTeam';
import InterventionList from './../../pages/InterventionList/InterventionList';
import ClientDetails from './../../pages/ClientDetails/ClientDetails';
import Page404 from './../../pages/Page404/Page404';
import ManagerDetails from './../../pages/ManagerDetails/ManagerDetails';
import Profile from './../../pages/Profile/Profile';
import Alerts from './../../pages/Alerts/Alerts';
import DetailPool from './../../pages/DetailPool/DetailPool';
// import Home from './../../pages/Home/Home';
import GlobalContext from '../../contexts/GlobalContext';
const Content = ({ sidebarIsOpen, toggleSidebar }) => {
  const { user } = React.useContext(GlobalContext);
  return (
    <Container
      fluid
      className={classNames('content', { 'is-open': sidebarIsOpen })}
    >
      <Topbar toggleSidebar={toggleSidebar} />
      <Routes>
        <Route path='/' element={<Profile />} />
        {user?.previleges.find((v) => v.name === 'GUSER') !== undefined && (
          <Route path='/utilisateurs' element={<Users />} />
        )}
        {user?.previleges.find((v) => v.name === 'GUSER') !== undefined && (
          <Route path='/utilisateurs/:userId' element={<UserDetails />} />
        )}
        <Route path='/manager/:userId' element={<ManagerDetails />} />
        <Route path='/clients/:userId' element={<ClientDetails />} />
        <Route path='/calendrier' element={<Calendrier />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/compte' element={<Profile />} />
        <Route path='/alerts' element={<Alerts />} />
        <Route path='/interventions' element={<InterventionList />} />
        <Route
          path='/interventions/:interventionId'
          element={<Intervention />}
        />
        <Route path='/teams' element={<Teams />} />
        <Route path='/parametres' element={<Page404 />} />
        <Route path='/teams/:teamId' element={<DetailsTeam />} />
        <Route path='/pool/:poolId' element={<DetailPool />} />
      </Routes>
    </Container>
  );
};

export default Content;
