import React from "react";
import classes from "./DetailsTeam.module.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import * as moment from "moment";
import "moment/locale/fr";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const DetailsTeam = () => {
  let { teamId } = useParams();
  let navigate = useNavigate();
  const [team, setTeam] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [activities, setActivities] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState("");
  const [commingActivities, setCommingActivities] = React.useState(null);

  const toggle = () => {
    setModal(!modal);
  };
  React.useEffect(() => {
    axios
      .get("/team/?id=" + teamId)
      .then((res) => {
        setTeam(res.data.team[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/auth/users?profile_id=3")
      .then((res) => {
        // setEmployees(res.data.users);
        var emp = res.data.users;
        axios
          .get("/auth/users?profile_id=4")
          .then((res) => {
            setEmployees(emp.concat(res.data.users));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    const today = new Date();
    axios
      .get(
        "/activity/?fromdate=" +
          today.getFullYear() +
          "-01-01&team_id=" +
          teamId
      )
      .then((res) => {
        let temp2 = res.data.activities;

        //delete from temp2 activities with status != 'accepted'
        temp2 = temp2.filter((activity) => {
          return activity.status === "accepted";
        });
        temp2.sort(function (b, a) {
          a = new Date(
            moment(a?.date.concat(" ", a.time)) + 1 * 60 * 60 * 1000
          );
          b = new Date(moment(b.date.concat(" ", b.time)) + 1 * 60 * 60 * 1000);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        var historique = [];
        var future = [];
        temp2.forEach((activity) => {
          if (activity.status === "done") {
            historique.push(activity);
          } else if (
            activity.status === "waiting" ||
            activity.status === "accepted"
          ) {
            future.push(activity);
          }
        });
        setCommingActivities(future);
        setActivities(historique);
        // console.log(temp2);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const getUser = () => {
    axios
      .get("/auth/users?id=" + selectedEmployee)
      .then((res) => {
        if (!res.data.user.team_id) {
          affectToTeam();
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "This user is already affected to team!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, affect it!",
          }).then((result) => {
            if (result.isConfirmed) {
              affectToTeam();
            }
          });
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const affectToTeam = () => {
    team &&
      axios
        .put("/auth/toTeam", {
          employee_id: selectedEmployee,
          team_id: team.id,
        })
        .then((res) => {
          // console.log(res.data);
          setRefresh(!refresh);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User affected to " + team.name + " successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log({ err });
        });
  };
  const delete_affected_team = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This user will be no longer in this team!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove from team!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put("/auth/delete_affected_team", {
            employee_id: id,
          })
          .then((res) => {
            // console.log(res.data);
            setRefresh(!refresh);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "User removed successfully from this team ",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    });
  };
  return (
    <div className={classes.mainContainer}>
      <Modal isOpen={modal} toggle={toggle} centered scrollable>
        <ModalHeader toggle={toggle}>Add Employee to team </ModalHeader>
        <ModalBody>
          <Container fluid>
            {employees && (
              <Row>
                <Col xs="12" md="12" lg="12">
                  <TextField
                    style={{ width: "100%" }}
                    id="employees_input"
                    select
                    label="Choose the employee"
                    variant="filled"
                    defaultValue=""
                    value={selectedEmployee}
                    onChange={(e) => {
                      setSelectedEmployee(e.target.value);
                    }}
                  >
                    {employees?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item?.lastName + " " + item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col>
              </Row>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              getUser();
              toggle();
            }}
            style={{ backgroundColor: "#6DCDFF", border: "none" }}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>

      {team && (
        <Container fluid className={classes.activityDetails}>
          <Row className={classes.activityDetailsTitle}>
            <Col>
              <h5 style={{ marginBottom: "0px" }}>{team.name}</h5>
            </Col>

            <Col>
              <p className={classes.detailsTitle}>
                Date de création:{" "}
                <span className={classes.detailsContent}>
                  {team.creation_date}
                </span>
              </p>
            </Col>
          </Row>
          <Row className={classes.groupRowContainer}>
            <Col xs="6"></Col>
            <Col xs="6" className={classes.groupColContainer}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0px" }}>Team's employees</h6>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <button onClick={toggle} className={classes.addButton}>
                    <img
                      src={require("../../assets/images/add.png")}
                      alt="add icon"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </Col>
              </Row>
              <Row className={classes.docContainer}>
                <Col className={classes.participantKids}>
                  {team &&
                    team.workers.map((child, index) => {
                      return (
                        <div className={classes.kidItem} key={index}>
                          <p>{child.username}</p>
                          <div>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              onClick={() => {
                                delete_affected_team(child.id);
                              }}
                            >
                              <img
                                src={require("../../assets/images/delete2.png")}
                                alt="details"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </button>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              onClick={() => {
                                navigate("/utilisateurs/".concat(child.id));
                              }}
                            >
                              <img
                                src={require("../../assets/images/detailsLink.png")}
                                alt="details"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={classes.rowHistoryContainer}>
            <Col xs={6} className={classes.colHistory}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0px" }}>Events history</h6>
                </Col>
              </Row>
              <Row className={classes.historiqueContainer}>
                <Col>
                  <Timeline>
                    {activities &&
                      activities.map((activity, index) => {
                        if (index !== activities.length - 1) {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {activities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>

                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        "/interventions/".concat(activity.id)
                                      );
                                    }}
                                  >
                                    <img
                                      src={require("../../assets/images/detailsLink.png")}
                                      alt="details"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </button>
                                </div>
                                <p style={{ color: "#8F9BB3" }}>
                                  {activity.activity_desc}
                                </p>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        } else {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {activities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        "/interventions/".concat(activity.id)
                                      );
                                    }}
                                  >
                                    <img
                                      src={require("../../assets/images/detailsLink.png")}
                                      alt="details"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </button>
                                </div>

                                <p style={{ color: "#8F9BB3" }}>
                                  {activity.activity_desc}
                                </p>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      })}
                  </Timeline>
                </Col>
              </Row>
            </Col>
            <Col xs={6} className={classes.colHistory}>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ margin: "0px" }}>Upcoming activities</h6>
                </Col>
              </Row>
              <Row className={classes.historiqueContainer}>
                <Col>
                  <Timeline>
                    {commingActivities &&
                      commingActivities.map((activity, index) => {
                        if (index !== commingActivities.length - 1) {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {commingActivities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>

                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        "/interventions/".concat(activity.id)
                                      );
                                    }}
                                  >
                                    <img
                                      src={require("../../assets/images/detailsLink.png")}
                                      alt="details"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </button>
                                </div>
                                <p style={{ color: "#8F9BB3" }}>
                                  {activity.activity_desc}
                                </p>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        } else {
                          return (
                            <TimelineItem key={activity.id}>
                              <TimelineOppositeContent
                                style={{ flex: 0.5 }}
                                color="text.secondary"
                              >
                                {commingActivities[index - 1]?.date !==
                                  activity.date && (
                                  <h5>
                                    {moment(
                                      new Date(
                                        moment(
                                          activity.date.concat(
                                            " ",
                                            activity.time
                                          )
                                        ) +
                                          1 * 60 * 60 * 1000
                                      )
                                    ).format("DD MMMM YYYY")}
                                  </h5>
                                )}
                              </TimelineOppositeContent>

                              <TimelineSeparator>
                                <TimelineDot />
                              </TimelineSeparator>
                              <TimelineContent>
                                <h5> {activity.time}</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{activity.name}</p>
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        "/interventions/".concat(activity.id)
                                      );
                                    }}
                                  >
                                    <img
                                      src={require("../../assets/images/detailsLink.png")}
                                      alt="details"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  </button>
                                </div>

                                <p style={{ color: "#8F9BB3" }}>
                                  {activity.activity_desc}
                                </p>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      })}
                  </Timeline>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default DetailsTeam;
