import React from 'react';
import { useNavigate } from 'react-router-dom';

const GroupItem = (props) => {
  // const { refresh, setRefresh } = props;
  let navigate = useNavigate();

  return (
    <tr>
      <td>{props.group.name}</td>

      <td>{props.group.creation_date} </td>
      <td>{props.group?.workers?.length}</td>
      <td>
        <div>
          <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={() => {
              navigate('/teams/'.concat(props.group?.id));
            }}
          >
            <img
              src={require('../../../assets/images/detailsLink.png')}
              alt='details'
              style={{ width: '25px', height: '25px', marginRight: '1rem' }}
            />
          </button>
        </div>
      </td>
    </tr>
  );
};
export default GroupItem;
