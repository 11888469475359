import React, { useEffect } from "react";
import Kalend, { CalendarView } from "kalend"; // import component
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import GlobalContext from "../../../contexts/GlobalContext";
import classes from "../Calendrier.module.css";
import "kalend/dist/styles/index.css"; // import styles
import moment from "moment";
import Swal from "sweetalert2";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import EditActivityModal from "../../../components/EditActivityModal/EditActivityModal";

const CalendarComponent = (props) => {
  const { user } = React.useContext(GlobalContext);
  const { refresh, setRefresh } = props;
  let navigate = useNavigate();
  const { activities } = React.useContext(GlobalContext);
  const [modal, setModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [eventData, setEventData] = React.useState(null);
  const toggle = () => {
    setModal(!modal);
  };
  const toggleEdit = () => {
    setEditModal(!editModal);
  };
  interface NewEventClickData {
    event: CalendarEvent;
    day: Date;
    hour: number;
  }

  const onNewEventClick = (data: NewEventClickData) => {
    console.log(data);
  };
  const onEventClick = (data, subEvent) => {
    setEventData(data);
    // console.log(data);
    toggle();
  };

  const ChangeStatut = (status) => {
    axios
      .put("/activity/status", {
        activity_id: eventData?.id,
        status: status,
      })
      .then((res) => {
        Swal.fire("Statut changé!", "Le statut a été changé.", "success");
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const createSheet = (eventData) => {
    axios
      .post("/intervention/create", {
        team_id: eventData?.activity?.team_id,
        event_id: eventData?.activity?.id,
        pool_id: eventData?.activity?.pool_id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <Col
      sm="12"
      md="12"
      lg={user?.role === "manager" ? 8 : 12}
      className={classes.calendarContainer}
    >
      <Modal
        isOpen={modal}
        toggle={toggle}
        // size='sm'
        centered
        backdrop={false}
      >
        <ModalHeader toggle={toggle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: eventData?.color,
              }}
            ></div>
            <h5 style={{ marginBottom: "0px", marginLeft: "0.5em" }}>
              {eventData?.summary}
            </h5>
          </div>
        </ModalHeader>
        {eventData && (
          <ModalBody>
            <Row>
              <Col xs="3">
                <h6 className={classes.popuptitles}>Heure début </h6>
              </Col>
              <Col xs="3">
                <p className={classes.popupInfo}>{eventData.activity.date} </p>
              </Col>
              <Col xs="3">
                <p className={classes.popupInfo}>{eventData.activity.time} </p>
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="3">
                <h6 className={classes.popuptitles}>Heure fin </h6>
              </Col>
              <Col xs="3">
                <p className={classes.popupInfo}>{eventData.activity.date} </p>
              </Col>
              <Col xs="3">
                <p className={classes.popupInfo}>
                  {moment(
                    new Date(
                      moment(
                        eventData.activity.date.concat(
                          " ",
                          eventData.activity.time
                        )
                      ) +
                        eventData.activity.duration * 60 * 1000
                    )
                  ).format("HH:mm")}{" "}
                </p>
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="3">
                <h6 className={classes.popuptitles}>Durée </h6>
              </Col>
              <Col xs="3">
                <p className={classes.popupInfo}>
                  {eventData?.activity?.duration}
                  {" Minutes "}
                </p>
              </Col>
            </Row>
            <Row className={classes.rowContainer}>
              <Col xs="3">
                <h6 className={classes.popuptitles}>Statut </h6>
              </Col>
              <Col xs="3">
                <p className={classes.popupInfo}>
                  {eventData.activity.status}{" "}
                </p>
              </Col>
            </Row>
          </ModalBody>
        )}
        <ModalFooter>
          {(eventData?.activity?.status === "waiting" ||
            eventData?.activity?.status === "refused") && (
            <>
              {user?.previleges.find((v) => v.name === "DEVENT") !==
                undefined && (
                <Button
                  onClick={() => {
                    toggle();
                    axios
                      .delete("/activity/delete", {
                        headers: { "Content-Type": "application/json" },
                        data: {
                          id: eventData?.id,
                          CalendarComponent,
                        },
                      })
                      .then((res) => {
                        Swal.fire(
                          "Activité supprimée!",
                          "L'activité a été supprimée.",
                          "success"
                        );
                        setRefresh(!refresh);
                      })
                      .catch((err) => {
                        console.log({ err });
                      });
                  }}
                  style={{ backgroundColor: "#E73A3A", border: "none" }}
                >
                  Delete event
                </Button>
              )}
            </>
          )}
          {eventData?.activity?.status !== "waiting" &&
            eventData?.activity?.status !== "refused" && (
              <Button
                onClick={() => {
                  toggle();
                  navigate(
                    "/interventions/".concat(
                      eventData?.activity?.intervention_id
                    )
                  );
                }}
                style={{ backgroundColor: "#6DCDFF", border: "none" }}
              >
                Intervention sheet
              </Button>
            )}
          {user.previleges.find((prev) => prev.name === "AREVENT") !==
            undefined &&
            eventData?.activity?.status === "waiting" && (
              <>
                <Button
                  onClick={() => {
                    toggle();
                    createSheet(eventData);
                    ChangeStatut("accepted");
                  }}
                  style={{ backgroundColor: "#00E096", border: "none" }}
                >
                  Accept
                </Button>
                <>
                  {user.role === "manager" && (
                    <Button
                      style={{ backgroundColor: "#17a2b8", border: "none" }}
                      onClick={toggleEdit}
                    >
                      Edit
                    </Button>
                  )}
                </>
                <EditActivityModal
                  event={eventData}
                  toggleModal={onEventClick}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  toggle={toggleEdit}
                  modal={editModal}
                />
              </>
            )}
          {eventData?.activity?.status === "accepted" && (
            <>
              <Button
                onClick={() => {
                  toggle();
                  ChangeStatut("done");
                }}
                style={{ backgroundColor: "#0095FF", border: "none" }}
              >
                Done
              </Button>
              {(user?.role === "manager" || user?.role === "teamlead") && (
                <Button
                  onClick={() => {
                    toggle();
                    ChangeStatut("canceled");
                  }}
                  style={{ border: "none" }}
                >
                  Cancel
                </Button>
              )}
            </>
          )}
          {user?.previleges.find((prev) => prev.name === "AREVENT") !==
            undefined &&
            eventData?.activity?.status === "waiting" && (
              <Button
                onClick={() => {
                  toggle();
                  ChangeStatut("refused");
                }}
                style={{ backgroundColor: "#FF2D55", border: "none" }}
              >
                Refuse
              </Button>
            )}
        </ModalFooter>
      </Modal>
      {activities && (
        <Kalend
          onEventClick={onEventClick}
          onNewEventClick={onNewEventClick}
          events={activities}
          initialDate={new Date().toISOString()}
          hourHeight={60}
          initialView={CalendarView.WEEK}
          timeFormat={"24"}
          weekDayStart={"Monday"}
          // calendarIDsHidden={['work']}
        />
      )}
    </Col>
  );
};

export default CalendarComponent;
