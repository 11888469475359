import React from "react";
import axios from "../../utils/axios";
import classes from "../Teams/Teams.module.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import AlertItem from "./AlertItem/AlertItem";
import GlobalContext from "../../contexts/GlobalContext";
const Alerts = () => {
  const { user } = React.useContext(GlobalContext);
  const [alerts, setAlerts] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [allAlerts, setAllAlerts] = React.useState();
  const [pageAlerts, setPageAlerts] = React.useState();
  const [modalAlert, setModalAlert] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [alertId, setAlertId] = React.useState("");

  const toggleAlert = (alert_id) => {
    // console.log(alert_id);
    if (modalAlert === false) {
      axios
        .get("/docs/?alert_id=" + alert_id)
        .then((res) => {
          // console.log(res.data);
          setDocs(res.data.docs);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDescription("");
      setAlertId("");
      setDocs([]);
    }

    setModalAlert(!modalAlert);
  };
  React.useEffect(() => {
    if (user?.role === "client") {
      axios
        .get("/alert/?page=" + pageCount + "&client_id=" + user?.id)
        .then((res) => {
          setAlerts(res.data.alerts);
          setPageAlerts(res.data.alerts);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get("/alert/?page=" + pageCount)
        .then((res) => {
          setAlerts(res.data.alerts);
          setPageAlerts(res.data.alerts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, refresh, user]);

  React.useEffect(() => {
    if (user?.role === "client") {
      axios
        .get("/alert?client_id=" + user?.id)
        .then((res) => {
          console.log(res.data.alerts);
          setAllAlerts(res.data.alerts);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get("/alert/")
        .then((res) => {
          console.log(res.data.alerts);
          setAllAlerts(res.data.alerts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, user]);

  const searchAlert = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredAlerts = allAlerts.filter((alert) => {
        return alert.client_name.toLowerCase().includes(search.toLowerCase());
      });
      setAlerts(filteredAlerts);
    } else {
      setAlerts(pageAlerts);
    }
  };

  const changeStatut = () => {
    axios
      .put("/alert/status", {
        status: "checked",
        id: alertId,
      })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={classes.mainContainer}>
      <Modal
        isOpen={modalAlert}
        toggle={toggleAlert}
        size="lg"
        centered
        scrollable
      >
        <ModalHeader toggle={toggleAlert}>Emergency Alert Details</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className={classes.rowContainer}>
              <Col>
                <Row className={classes.sectionContainerTitle}>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ margin: "0px" }}>Emergency description</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>{description && <p>{description}</p>}</Col>
                </Row>
                <Row className={classes.sectionContainerTitle}>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <h6 style={{ margin: "0px" }}>Documents associés</h6>
                  </Col>
                </Row>
                <Row className={classes.docContainer}>
                  <Col>
                    {docs.map((doc, index) => {
                      return (
                        <Row className={classes.fileContainer} key={index}>
                          <Col
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={require("../../assets/images/file.png")}
                              alt="file icon"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "1rem",
                              }}
                            />
                            <p>{doc.fileName}</p>
                          </Col>
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button className={classes.docButtons}>
                              <img
                                src={require("../../assets/images/downloadDoc.png")}
                                alt="details"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                }}
                                onClick={() => {
                                  window.open(
                                    process.env.REACT_APP_APIURL +
                                      doc.url.slice(1)
                                  );
                                }}
                              />
                            </button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggleAlert}
            style={{
              backgroundColor: "#DCDCDC",
              border: "none",
              color: "#717171",
            }}
          >
            Cancel
          </Button>
          {user?.role === "manager" && (
            <Button
              onClick={() => {
                changeStatut();
                toggleAlert();
              }}
              style={{
                backgroundColor: "#6DCDFF",
                border: "none",
              }}
            >
              Checked
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <div className={classes.tableContainer}>
        <table className={classes.listingTable}>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Creation date</th>
              <th>Priority</th>
              <th>Client mobile</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {alerts &&
              alerts.map((alert, index) => (
                <AlertItem
                  setDescription={setDescription}
                  setAlertId={setAlertId}
                  toggleAlert={toggleAlert}
                  key={index}
                  alert={alert}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>
                <button
                  className={classes.pagintionNextPrevButton}
                  onClick={() => {
                    if (pageCount - 1 > 0) {
                      setPageCount(pageCount - 1);
                    }
                  }}
                >
                  <img
                    style={{
                      height: "14px",
                      width: "16px",
                      marginRight: "0.5em",
                    }}
                    src={require("../../assets/images/prev.png")}
                    alt="previous"
                  />
                  Prev
                </button>
              </th>
              <th colSpan="3" style={{ padding: "0px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button className={classes.pagintionButton}>
                    {pageCount}
                  </button>
                  <button
                    className={classes.pagintionButtonNxt}
                    onClick={() => {
                      if (alerts.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    {pageCount + 1}
                  </button>
                </div>
              </th>

              <th colSpan="2">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "2em",
                  }}
                >
                  <button
                    className={classes.pagintionNextPrevButton}
                    onClick={() => {
                      if (alerts.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    <img
                      style={{
                        height: "14px",
                        width: "16px",
                        marginRight: "0.5em",
                      }}
                      src={require("../../assets/images/next.png")}
                      alt="previous"
                    />
                    Next
                  </button>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
export default Alerts;
