import React from "react";
import axios from "../../utils/axios";
import classes from "./InterventionList.module.css";
import InterventionItem from "./InterventionItem/InterventionItem";
import GlobalContext from "../../contexts/GlobalContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TextField } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import { Autocomplete } from "@mui/material";
const InterventionList = () => {
  const { user } = React.useContext(GlobalContext);
  const [toggle, setToggle] = React.useState(false);
  const [interventions, setInterventions] = React.useState();
  const [refresh, setRefresh] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [allInterventions, setAllInterventions] = React.useState();
  const [pageInterventions, setPageInterventions] = React.useState();
  const [clients, setClients] = React.useState([]);
  const [filter, setFilter] = React.useState("status");
  const [perPage, setPerPage] = React.useState(10);
  const [paginate, setPaginate] = React.useState(false);
  const [readyToExport, setReadyToExport] = React.useState(false);

  const [Subinterventions, setSubInterventions] = React.useState([]);
  const [interventionKeys, setInterventionKeys] = React.useState([]);
  const [interventionValues, setInterventionValues] = React.useState([]);

  const [productsKeys, setProductsKeys] = React.useState([]);
  const [productsValues, setProductsValues] = React.useState([]);

  const [phChartDataset, setPhChartDataset] = React.useState(null);
  const [chloreChartDataset, setChloreChartDataset] = React.useState(null);

  const [dateDebut, setDateDebut] = React.useState("");
  const [dateFin, setDateFin] = React.useState("");
  const [idClient, setIdClient] = React.useState();

  const handleExport = () => {
    axios
      .post("intervention/download/sheet", {
        date_debut: dateDebut,
        date_fin: dateFin,
        id_client: idClient,
      })
      .then((res) => {
        var temp = res.data.interventions;
        temp.sort(function (a, b) {
          var key1 = new Date(a.event_date);
          var key2 = new Date(b.event_date);
          if (key1 < key2) {
            return -1;
          } else if (key1 === key2) {
            return 0;
          } else {
            return 1;
          }
        });
        // console.log(temp);
        setSubInterventions(temp);
        var keys = [];
        var values = [];
        var pKeys = [];
        var pValues = [];
        let dataset = [];
        let chloreDataset = [];
        temp.map((intervention) => {
          // console.log(JSON.parse(intervention?.schema));
          let ph = JSON.parse(intervention?.formData)?.ANNALYSES["PH"];
          let chlore = JSON.parse(intervention?.formData)?.ANNALYSES[
            "CHLORE LIBRE"
          ];
          if (ph) {
            dataset.push({
              x: intervention.event_date,
              y: ph,
            });
          }
          if (chlore) {
            chloreDataset.push({
              x: intervention.event_date,
              y: chlore,
            });
          }
          return true;
        });
        let tempDataset = {
          label: "PH",
          data: dataset,
          fill: false,
          borderColor: "#FF2D55",
          backgroundColor: "#FF2D55",
          tension: 0.1,
        };
        let tempDataset2 = {
          label: "CHLORE LIBRE",
          data: chloreDataset,
          fill: false,
          borderColor: "#FF9D2B",
          backgroundColor: "#FF9D2B",
          tension: 0.1,
        };
        setPhChartDataset({
          datasets: [tempDataset],
        });
        setChloreChartDataset({
          datasets: [tempDataset2],
        });
        //extract analyses keys
        temp.forEach((intervention) => {
          let x = JSON.parse(intervention.uischema);
          let k = x?.ANNALYSES["ui:order"];
          k.forEach((key) => {
            if (!keys.includes(key)) {
              keys.push(key);
            }
          });
        });
        //extract prodict keys
        temp.forEach((intervention) => {
          let x = JSON.parse(intervention.uischema);
          let k = x?.PRODUITS["ui:order"];
          k.forEach((key) => {
            if (!pKeys.includes(key)) {
              pKeys.push(key);
            }
          });
        });
        //extract analyses values

        temp.forEach((intervention) => {
          var value = [];
          var x = JSON.parse(intervention?.formData);
          keys.forEach((key) => {
            value.push(x?.ANNALYSES[key]);
          });

          values.push(value);
        });
        //extract product values
        temp.forEach((intervention) => {
          var value = [];
          var x = JSON.parse(intervention?.formData);

          pKeys.forEach((key) => {
            value.push(x?.PRODUITS[key]);
          });

          pValues.push(value);
        });
        setInterventionKeys(keys);
        setInterventionValues(values);
        console.log(values);
        setProductsValues(pValues);
        setProductsKeys(pKeys);
      });
  };

  const [csvValues, setCsvValues] = React.useState([]);

  React.useEffect(() => {
    let tempValuesCSV = [];
    tempValuesCSV.push(interventionKeys);
    console.log(interventions);
    console.log(interventionValues);
    tempValuesCSV[0] = [...tempValuesCSV[0], ...productsKeys];
    interventionValues.forEach((elem, idx) => {
      tempValuesCSV.push(elem);
      tempValuesCSV[idx + 1] = [
        ...tempValuesCSV[idx + 1],
        ...productsValues[idx],
      ];
    });

    tempValuesCSV[0].unshift("Nom Pool");
    tempValuesCSV[0].unshift("Nom Client");
    tempValuesCSV[0].unshift("Date");

    Subinterventions.forEach((elem, idx) => {
      tempValuesCSV[idx + 1].unshift(elem.pool_name);
      tempValuesCSV[idx + 1].unshift(
        elem.client_name + " " + elem.client_lastname
      );
      tempValuesCSV[idx + 1].unshift(elem.event_date);
    });

    setCsvValues(tempValuesCSV);

    if (toggle && tempValuesCSV.length < 2) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No Intervention found !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [interventionValues, productsValues]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };

  React.useEffect(() => {
    if (user?.role === "manager") {
      axios
        .get("/intervention/?page=" + pageCount + "&per_page=" + perPage)
        .then((res) => {
          setInterventions(res.data.interventions);
          setPageInterventions(res.data.interventions);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setRefresh(false);
        });
    } else {
      user?.team_id &&
        axios
          .get(
            "/intervention/?page=" +
              pageCount +
              "&team_id=" +
              user.team_id +
              "&per_page=" +
              perPage
          )
          .then((res) => {
            setInterventions(res.data.interventions);
            setPageInterventions(res.data.interventions);
          })
          .catch((err) => {
            console.log(err);
          });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, user, paginate]);

  React.useEffect(() => {
    axios
      .get(`/auth/users`)
      .then((res) => {
        var temp = [];
        res.data.users.map((user) => {
          if (user.role === "client") {
            temp.push({
              id: user.id,
              label: user?.lastName + " " + user?.name,
            });
          }
          return true;
        });
        setClients(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  React.useEffect(() => {
    if (user?.role === "manager") {
      axios
        .get("/intervention/")
        .then((res) => {
          setAllInterventions(res.data.interventions);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setRefresh(false);
        });
    } else {
      user?.team_id &&
        axios
          .get("/intervention/?team_id=" + user.team_id)
          .then((res) => {
            // console.log(res.data.interventions);
            setAllInterventions(res.data.interventions);
          })
          .catch((err) => {
            console.log(err);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const searchIntervention = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredInterventions = allInterventions.filter((intervention) => {
        if (filter === "client_name") {
          return intervention.client_name
            .toLowerCase()
            .includes(search.toLowerCase());
        } else if (filter === "pool_name") {
          return intervention.pool_name
            .toLowerCase()
            .includes(search.toLowerCase());
        } else if (filter === "team_name") {
          return intervention.team_name
            .toLowerCase()
            .includes(search.toLowerCase());
        } else if (filter === "status") {
          return intervention.event_status
            .toLowerCase()
            .includes(search.toLowerCase());
        }
        return true;
      });
      setInterventions(filteredInterventions);
    } else {
      setInterventions(pageInterventions);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.tableContainer}>
        <table>
          <thead style={{ backgroundColor: "white", color: "#222B45" }}>
            <tr>
              <th colSpan="4">Interventions list</th>

              <th style={{ width: "260px", display: "flex" }}>
                <Button style={{ marginRight: "10px" }} onClick={handleToggle}>
                  Export
                </Button>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Filter with
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter}
                    style={{ width: "100%" }}
                    label=" Filter with"
                    onChange={handleChangeFilter}
                  >
                    <MenuItem value={"client_name"}>Client</MenuItem>
                    <MenuItem value={"team_name"}>Team</MenuItem>
                    <MenuItem value={"pool_name"}>Pool</MenuItem>
                    <MenuItem value={"status"}>Status</MenuItem>
                  </Select>
                </FormControl>
              </th>
              <th style={{ width: "250px" }}>
                <div
                  style={{
                    position: "relative",
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={searchIntervention}
                    className={classes.searchInput}
                  />
                  <img
                    src={require("../../assets/images/search.png")}
                    alt="search"
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "absolute",
                      right: "10px",
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Intervention status</th>
              <th>Intervention date</th>
              <th>Pool name</th>
              <th>Team name</th>
              <th>Client name</th>
              <th>Sheet</th>
            </tr>
          </thead>
          <tbody>
            {interventions &&
              interventions.map((intervention, index) => (
                <InterventionItem
                  key={index}
                  intervention={intervention}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>
                <button
                  className={classes.pagintionNextPrevButton}
                  onClick={() => {
                    if (pageCount - 1 > 0) {
                      setPageCount(pageCount - 1);
                    }
                  }}
                >
                  <img
                    style={{
                      height: "14px",
                      width: "16px",
                      marginRight: "0.5em",
                    }}
                    src={require("../../assets/images/prev.png")}
                    alt="previous"
                  />
                  Prev
                </button>
              </th>
              <th
                style={{
                  display: "flex",
                }}
              >
                <p>Per page:</p>
                <input
                  type="number"
                  value={perPage}
                  onChange={(e) => setPerPage(e.target.value)}
                  className={classes.paginationInput}
                />
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => {
                    if (perPage > 0) {
                      setPaginate(!paginate);
                    }
                  }}
                >
                  <img
                    style={{
                      height: "26px",
                      width: "26px",
                    }}
                    src={require("../../assets/images/check.png")}
                    alt="previous"
                  />
                </button>
              </th>
              <th style={{ padding: "0px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button className={classes.pagintionButton}>
                    {pageCount}
                  </button>
                  <button
                    className={classes.pagintionButtonNxt}
                    onClick={() => {
                      if (interventions.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    {pageCount + 1}
                  </button>
                </div>
              </th>

              <th colSpan="3">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "2em",
                  }}
                >
                  <button
                    className={classes.pagintionNextPrevButton}
                    onClick={() => {
                      if (interventions.length > 0) {
                        setPageCount(pageCount + 1);
                      }
                    }}
                  >
                    <img
                      style={{
                        height: "14px",
                        width: "16px",
                        marginRight: "0.5em",
                      }}
                      src={require("../../assets/images/next.png")}
                      alt="previous"
                    />
                    Next
                  </button>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <Modal isOpen={toggle} toggle={handleToggle} size="lg" centered>
        <ModalHeader toggle={handleToggle}>
          Export Intervention Sheet
        </ModalHeader>
        <ModalBody style={{ padding: "2rem" }}>
          <label>Date début:</label>
          <TextField
            style={{ width: "100%", marginBottom: "10px" }}
            id="date_debut"
            type="Date"
            onChange={(e) => {
              setDateDebut(e.target.value);
            }}
          />
          <label>Date fin:</label>
          <TextField
            style={{ width: "100%", marginBottom: "10px" }}
            id="date_fin"
            type="Date"
            onChange={(e) => {
              setDateFin(e.target.value);
            }}
          />
          {/* <TextField
            style={{ width: "90%" }}
            id="clients_input"
            select
            label="Choose client"
            variant="filled"
            onChange={(e) => {
              setIdClient(e.target.value);
            }}
          >
            {clients?.map((client, index) => (
              <MenuItem key={index} value={client.id}>
                {client?.lastName + " " + client?.name}
              </MenuItem>
            ))}
          </TextField> */}
          <label>Client:</label>
          <Autocomplete
            options={clients}
            id="clients_input"
            onChange={(_event, newValue) => {
              setIdClient(newValue.id);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </ModalBody>
        <ModalFooter>
          {csvValues.length >= 2 && (
            <CSVLink data={csvValues} download={"Analyse.csv"}>
              Download CSV
            </CSVLink>
          )}
          <Button onClick={handleExport}>Export</Button>

          <Button onClick={handleToggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default InterventionList;
