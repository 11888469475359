import React from "react";
import axios from "../../utils/axios";
import classes from "./AddActivityModal.module.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import Swal from "sweetalert2";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";

const AddActivityModal = (props) => {
  const { toggle, modal, refresh, setRefresh, selectedTeam, user } = props;
  const [eventName, setEventName] = React.useState("");
  const [repeate, setRepeate] = React.useState("once");
  const [weekday, setweekday] = React.useState("0");
  const [fromDate, setFromDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [time, setTime] = React.useState(moment(new Date()).format("HH:mm"));
  const [duration, setDuration] = React.useState("");
  const [clients, setClients] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState("");
  const [pools, setPools] = React.useState([]);
  const [selectedPool, setSelectedPool] = React.useState("");
  const [poolModal, setPoolModal] = React.useState(false);
  const [poolData, setPoolData] = React.useState({
    name: "",
    address: "",
    city: "",
    country: "",
    region: "",
    client_id: "",
  });
  const togglePoolModal = () => {
    setPoolModal(!poolModal);
  };
  const getPools = (itemValue) => {
    axios
      .get(`/pool/?client_id=` + itemValue)
      .then((res) => {
        setPools(res.data.pools);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    axios
      .get(`/auth/users`)
      .then((res) => {
        var temp = [];
        res.data.users.map((user) => {
          if (user.role === "client") {
            temp.push({
              id: user.id,
              label: user?.lastName + " " + user?.name,
            });
          }
          return true;
        });
        console.log(temp);
        setClients(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);
  const createActivity = () => {
    let data;
    if (
      duration &&
      time &&
      fromDate &&
      eventName &&
      selectedClient &&
      selectedPool &&
      selectedTeam
    ) {
      if (repeate === "once") {
        data = {
          duration: duration,
          time: time,
          date: fromDate,
          team_id: selectedTeam,
          manager_id: user.id,
          name: eventName,
          pool_id: selectedPool,
        };
      } else if (repeate === "weekly" || repeate === "monthly") {
        data = {
          duration: duration,
          time: time,
          date: fromDate,
          date_fin: toDate,
          weekday: weekday,
          repeate: repeate,
          team_id: selectedTeam,
          manager_id: user.id,
          name: eventName,
          pool_id: selectedPool,
        };
      }
      console.log(data);
      if (data) {
        try {
          axios
            .post("/activity/create", data)
            .then((res) => {
              console.log(res.data);

              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Activity created Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
              setRefresh(!refresh);
            })
            .catch((err) => {
              console.log({ err });
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Please fill all form!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all fields",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const createPool = () => {
    if (
      poolData.name !== "" &&
      poolData.address !== "" &&
      poolData.city !== "" &&
      poolData.country !== "" &&
      poolData.region !== "" &&
      poolData.client_id !== ""
    ) {
      axios
        .post(`/pool/create`, {
          name: poolData.name,
          address: poolData.address,
          city: poolData.city,
          country: poolData.country,
          region: poolData.region,
          client_id: poolData.client_id,
        })
        .then((res) => {
          console.log(res.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Pool added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setPoolModal(!poolModal);
          getPools(poolData.client_id);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill all required fields",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" centered scrollable>
      <ModalHeader toggle={toggle}>Add an event</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Modal
            isOpen={poolModal}
            toggle={togglePoolModal}
            size="lg"
            centered
            scrollable
          >
            <ModalHeader toggle={togglePoolModal}>Add Pool</ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <Autocomplete
                      options={clients}
                      variant="filled"
                      id="clients_input"
                      onChange={(_event, newValue) => {
                        setPoolData({ ...poolData, client_id: newValue.id });
                      }}
                      value={selectedClient}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Choose client"
                        />
                      )}
                    />
                    {/* <TextField
                      style={{ width: "100%" }}
                      id="clients_input"
                      select
                      label="Choose client"
                      variant="filled"
                      value={poolData.client_id}
                      onChange={(e) =>
                        setPoolData({ ...poolData, client_id: e.target.value })
                      }
                    >
                      {clients?.map((client, index) => (
                        <MenuItem key={index} value={client.id}>
                          {client.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="name_pool_input"
                      variant="filled"
                      label="Pool name"
                      value={poolData.name}
                      onChange={(e) =>
                        setPoolData({ ...poolData, name: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_address_input"
                      variant="filled"
                      label="Address"
                      value={poolData.address}
                      onChange={(e) =>
                        setPoolData({ ...poolData, address: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_country_input"
                      variant="filled"
                      label="Country"
                      value={poolData.country}
                      onChange={(e) =>
                        setPoolData({ ...poolData, country: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_city_input"
                      variant="filled"
                      value={poolData.city}
                      label="City"
                      onChange={(e) =>
                        setPoolData({ ...poolData, city: e.target.value })
                      }
                    />
                  </Col>
                </Row>

                <Row className={classes.rowContainer}>
                  <Col xs="12" md="12" lg="12">
                    <TextField
                      required
                      style={{ width: "100%" }}
                      id="pool_region_input"
                      variant="filled"
                      label="Region"
                      value={poolData.region}
                      onChange={(e) =>
                        setPoolData({ ...poolData, region: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  createPool();
                }}
                style={{
                  backgroundColor: "#6DCDFF",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                Add
              </Button>
              <Button
                onClick={togglePoolModal}
                style={{
                  backgroundColor: "#DCDCDC",
                  border: "none",
                  color: "#717171",
                  borderRadius: "10px",
                }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </Modal>
          <Row className={classes.rowContainer}>
            <Col xs="12" md="12" lg="12">
              <Autocomplete
                options={clients}
                variant="filled"
                id="clients_input"
                onChange={(_event, newValue) => {
                  console.log(newValue);
                  setSelectedClient(newValue);
                  setPoolData({ ...poolData, client_id: newValue.id });
                  getPools(newValue.id);
                  setSelectedPool("");
                }}
                value={selectedClient}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Choose client"
                  />
                )}
              />
            </Col>
          </Row>
          {/* <Row className={classes.rowContainer}>
            <Col xs="12" md="12" lg="12">
              <TextField
                style={{ width: "100%" }}
                id="clients_input"
                label="Choose client"
                variant="filled"
                defaultValue=""
                value={selectedClient}
                onChange={(e) => {
                  setSelectedClient(e.target.value);
                  getPools(e.target.value);
                  setSelectedPool("");
                }}
              >
                {clients?.map((client, index) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client?.lastName + " " + client?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </Row> */}
          <Row className={classes.rowContainer}>
            <Col xs="9" md="9" lg="9">
              <TextField
                style={{ width: "100%" }}
                id="pool_input"
                select
                label="Choose pool"
                variant="filled"
                defaultValue=""
                value={selectedPool}
                onChange={(e) => {
                  setSelectedPool(e.target.value);
                }}
              >
                {pools?.map((pool) => (
                  <MenuItem key={pool.id} value={pool.id}>
                    {pool.name}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col
              xs="3"
              md="3"
              lg="3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button
                style={{
                  width: "100%",
                  height: "80%",
                  backgroundColor: "#c5a47e",
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={togglePoolModal}
              >
                <img
                  src={require("../../assets/images/addWhite.png")}
                  alt="add icon"
                  style={{ width: "35px", height: "35px" }}
                />
                Add Pool
              </button>
            </Col>
          </Row>
          <Row className={classes.rowContainer}>
            <Col xs="12" md="12" lg="12">
              <TextField
                required
                style={{ width: "100%" }}
                id="event_name_input"
                variant="filled"
                label="Event Name"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className={classes.rowContainer}>
            <Col>
              <TextField
                style={{ width: "100%" }}
                id="role_input"
                select
                label="Répétition"
                value={repeate}
                onChange={(e) => {
                  setRepeate(e.target.value);
                }}
                variant="filled"
              >
                <MenuItem value="once">Once</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </TextField>
            </Col>
            {repeate === "weekly" && (
              <Col>
                <TextField
                  style={{ width: "100%" }}
                  id="weekday_input"
                  select
                  label="Jour de semaine"
                  value={weekday}
                  onChange={(e) => {
                    setweekday(e.target.value);
                  }}
                  variant="filled"
                >
                  <MenuItem value="0">Monday</MenuItem>
                  <MenuItem value="1">Tuesday</MenuItem>
                  <MenuItem value="2">Wednesday</MenuItem>
                  <MenuItem value="3">Thursday</MenuItem>
                  <MenuItem value="4">Friday</MenuItem>
                  <MenuItem value="5">Saturday</MenuItem>
                  <MenuItem value="6">Sunday</MenuItem>
                </TextField>
              </Col>
            )}
          </Row>
          {repeate === "once" && (
            <Row className={classes.rowContainer}>
              <Col>
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="date_input"
                  type="Date"
                  variant="filled"
                  label="Date"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    console.log(e.target.value);
                  }}
                />
              </Col>
            </Row>
          )}
          {(repeate === "weekly" || repeate === "monthly") && (
            <Row className={classes.rowContainer}>
              <Col xs="12" md="6" lg="6">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="from_date_input"
                  type="Date"
                  variant="filled"
                  label="Date debut"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </Col>
              <Col xs="12" md="6" lg="6">
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="to_date_input"
                  type="Date"
                  variant="filled"
                  label="Date fin"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                />
              </Col>
            </Row>
          )}

          <Row className={classes.rowContainer}>
            <Col xs="12" md="6" lg="6">
              <TextField
                required
                style={{ width: "100%" }}
                id="time_input"
                type="Time"
                variant="filled"
                label="Temps"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              />
            </Col>
            <Col xs="12" md="6" lg="6">
              <TextField
                required
                style={{ width: "100%" }}
                id="duration_input"
                type="Number"
                variant="filled"
                label="Durée"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          // color='primary'
          onClick={() => {
            toggle();
            createActivity();
          }}
          style={{ backgroundColor: "#6DCDFF", border: "none" }}
        >
          Ajouter
        </Button>
        <Button
          onClick={toggle}
          style={{
            backgroundColor: "#DCDCDC",
            border: "none",
            color: "#717171",
          }}
        >
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddActivityModal;
