import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <div className="bgLight">
      <svg className="svg" viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </div>
  );
};

export default Loader;
