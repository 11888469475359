import React from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import classes from '../ClientDetails/ClientDetails.module.css';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from 'reactstrap';
import Swal from 'sweetalert2';
import InterventionItem from './../InterventionList/InterventionItem/InterventionItem';
const DetailPool = () => {
  let { poolId } = useParams();
  const [pool, setPool] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [modalUpload, setModalUpload] = React.useState(false);
  const [docs, setDocs] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [interventions, setInterventions] = React.useState();
  const [pageCount, setPageCount] = React.useState(1);
  const [allInterventions, setAllInterventions] = React.useState();
  const [pageInterventions, setPageInterventions] = React.useState();

  const toggleUpload = () => {
    setModalUpload(!modalUpload);
  };
  const uploadFileHandler = () => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
      axios
        .post('/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          // console.log(res.data);

          pool &&
            file &&
            axios
              .post('/docs/add', {
                user_id: pool.client_id,
                pool_id: poolId,
                url: res.data.file_url,
                fileName: file.name,
              })
              .then((res) => {
                // console.log(res.data);

                setRefresh(!refresh);
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Fichier uploadé avec succès',
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((err) => {
                console.log({ err });
              });
        })
        .catch((err) => {
          console.log({ err });
        });
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Veuillez selectionner un fichier',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const deleteFileHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This document will be no longer accessible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete('/docs/delete', {
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({
              id: id,
            }),
          })
          .then((res) => {
            // console.log(res.data);
            setRefresh(!refresh);
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    });
  };
  React.useEffect(() => {
    axios
      .get('/docs/?pool_id=' + poolId)
      .then((res) => {
        setDocs(res.data.docs);
        // console.log(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get('/pool/?id=' + poolId)
      .then((res) => {
        // console.log(res.data.pool[0]);
        setPool(res.data.pool[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  const searchIntervention = (e) => {
    const search = e.target.value;
    if (search.length > 0) {
      const filteredInterventions = allInterventions.filter((intervention) => {
        return intervention.creation_date
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setInterventions(filteredInterventions);
    } else {
      setInterventions(pageInterventions);
    }
  };

  React.useEffect(() => {
    axios
      .get('/intervention/?page=' + pageCount + '&pool_id=' + poolId)
      .then((res) => {
        setInterventions(res.data.interventions);
        setPageInterventions(res.data.interventions);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  React.useEffect(() => {
    axios
      .get('/intervention/?pool_id=' + poolId)
      .then((res) => {
        // console.log(res.data.interventions);
        setAllInterventions(res.data.interventions);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.mainContainer}>
      {pool && (
        <Container fluid className={classes.activityDetails}>
          <Modal isOpen={modalUpload} toggle={toggleUpload} centered scrollable>
            <ModalHeader toggle={toggleUpload}>Add Document </ModalHeader>
            <ModalBody>
              <Container fluid>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <label className={classes.customFileUpload}>
                    <input
                      type='file'
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                    Ajouter Votre Document
                    <img
                      src={require('../../assets/images/upload.png')}
                      alt='upload icon'
                      style={{
                        width: '30px',
                        height: '30px',
                        marginLeft: '1rem',
                      }}
                    />
                  </label>
                  {file && <p className={classes.nameFile}>{file.name}</p>}
                </div>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={toggleUpload}
                style={{
                  backgroundColor: '#DCDCDC',
                  border: 'none',
                  color: '#717171',
                }}
              >
                Annuler
              </Button>
              <Button
                onClick={() => {
                  uploadFileHandler();
                  toggleUpload();
                }}
                style={{ backgroundColor: '#6DCDFF', border: 'none' }}
              >
                Ajouter
              </Button>
            </ModalFooter>
          </Modal>
          <Row className={classes.activityDetailsTitle}>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <p className={classes.detailsTitle}>
                Client:{' '}
                <span className={classes.detailsContent}>
                  {pool.client_name} {pool.client_lastname}
                </span>
              </p>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <p className={classes.detailsTitle}>
                Pool name :{' '}
                <span className={classes.detailsContent}>{pool.name}</span>
              </p>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <p className={classes.detailsTitle}>Address: </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <div className={classes.detailsContent}>
                  <p>
                    {pool?.address} {pool?.city}{' '}
                  </p>
                  <p>
                    {pool?.region}, {pool?.country}
                  </p>
                </div>
              </div>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <p className={classes.detailsTitle}>
                Date d'inscription:{' '}
                <span className={classes.detailsContent}>
                  {pool.creation_date}
                </span>
              </p>
            </Col>
          </Row>
          <Row className={classes.rowContainerDoc}>
            <Col>
              <Row className={classes.sectionContainerTitle}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <h6 style={{ margin: '0px' }}>Documents associés</h6>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <button onClick={toggleUpload} className={classes.addButton}>
                    <img
                      src={require('../../assets/images/add.png')}
                      alt='add icon'
                      style={{ width: '20px', height: '20px' }}
                    />
                  </button>
                </Col>
              </Row>
              <Row className={classes.docContainer}>
                <Col>
                  {docs?.map((doc, index) => {
                    return (
                      <Row className={classes.fileContainer} key={index}>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={require('../../assets/images/file.png')}
                            alt='file icon'
                            style={{
                              width: '40px',
                              height: '40px',
                              marginRight: '1rem',
                            }}
                          />
                          <p>{doc.fileName}</p>
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <button className={classes.docButtons}>
                            <img
                              src={require('../../assets/images/downloadDoc.png')}
                              alt='details'
                              style={{
                                width: '25px',
                                height: '25px',
                              }}
                              onClick={() => {
                                window.open(
                                  process.env.REACT_APP_APIURL +
                                    doc.url.slice(1)
                                );
                              }}
                            />
                          </button>

                          <button
                            className={classes.docButtons}
                            onClick={() => {
                              deleteFileHandler(doc.id);
                            }}
                          >
                            <img
                              src={require('../../assets/images/deleteDoc.png')}
                              alt='listMode'
                              style={{ width: '28px', height: '28px' }}
                            />
                          </button>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className={classes.tableContainer}>
            <table>
              <thead style={{ backgroundColor: 'white', color: '#222B45' }}>
                <tr>
                  <th colSpan='5'>Interventions list</th>

                  <th style={{ width: '250px' }}>
                    <div
                      style={{
                        position: 'relative',
                        width: '90%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type='text'
                        placeholder='Search'
                        onChange={searchIntervention}
                        className={classes.searchInput}
                      />
                      <img
                        src={require('../../assets/images/search.png')}
                        alt='search'
                        style={{
                          width: '30px',
                          height: '30px',
                          position: 'absolute',
                          right: '10px',
                        }}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Intervention status</th>
                  <th>Intervention date</th>
                  <th>Pool name</th>
                  <th>Team name</th>
                  <th>Client name</th>
                  <th>Sheet</th>
                </tr>
              </thead>
              <tbody>
                {interventions &&
                  interventions.map((intervention, index) => (
                    <InterventionItem
                      key={index}
                      intervention={intervention}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    <button
                      className={classes.pagintionNextPrevButton}
                      onClick={() => {
                        if (pageCount - 1 > 0) {
                          setPageCount(pageCount - 1);
                        }
                      }}
                    >
                      <img
                        style={{
                          height: '14px',
                          width: '16px',
                          marginRight: '0.5em',
                        }}
                        src={require('../../assets/images/prev.png')}
                        alt='previous'
                      />
                      Prev
                    </button>
                  </th>
                  <th colSpan='3' style={{ padding: '0px' }}>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <button className={classes.pagintionButton}>
                        {pageCount}
                      </button>
                      <button
                        className={classes.pagintionButtonNxt}
                        onClick={() => {
                          if (interventions.length > 0) {
                            setPageCount(pageCount + 1);
                          }
                        }}
                      >
                        {pageCount + 1}
                      </button>
                    </div>
                  </th>

                  <th colSpan='2'>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '2em',
                      }}
                    >
                      <button
                        className={classes.pagintionNextPrevButton}
                        onClick={() => {
                          if (interventions.length > 0) {
                            setPageCount(pageCount + 1);
                          }
                        }}
                      >
                        <img
                          style={{
                            height: '14px',
                            width: '16px',
                            marginRight: '0.5em',
                          }}
                          src={require('../../assets/images/next.png')}
                          alt='previous'
                        />
                        Next
                      </button>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </Container>
      )}
    </div>
  );
};

export default DetailPool;
